/* TimerIndex Name */
/* External Imports */
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";

/* Local Imports */

// components
import StageTimer from "./stageTimer";

// store
import { LaborEvent } from "@/store/services/pregnancy";

// styles
import styles from "./styles.module.scss";

export interface TimerEvent {
  label: string;
  event: LaborEvent;
  endEvents?: LaborEvent[];
}
/* TimerIndex Typescript Interface */
interface TimerIndexProps {
  events: TimerEvent[];
}

export default function TimerIndex({ events }: TimerIndexProps) {
  /* Redux */

  /* Local State */
  const timerRefs = useRef<Array<any>>([]);
  const [currentTimeDisplay, setCurrentTimeDisplay] =
    useState<string>("00:00:00");

  useEffect(() => {
    setCurrentTimeDisplay(dayjs().format("h:mm:ss a"));

    const index = setInterval(() => {
      setCurrentTimeDisplay(dayjs().format("h:mm:ss a"));
      timerRefs.current.forEach(timer => {
        timer.intervalUpdate();
      });
    }, 1000);

    return () => clearInterval(index);
  }, []);

  useEffect(() => {
    timerRefs.current.forEach(timer => {
      timer.updateEvent();
    })
  }, [events])

  return (
    <div className={clsx(styles.TimerIndex)}>
      {events &&
        events.map((timerEvent, index) => (
          <div key={timerEvent.label} className={styles.timestamp}>
            <p className="xLight t5">{timerEvent.label}</p>
            <StageTimer
              event={timerEvent}
              ref={ref => {
                if (ref) {
                  timerRefs.current[index] = ref;
                }
              }}
            />
          </div>
        ))}
      <div className={styles.timestamp}>
        <p className="xLight t5">Current time</p>
        <div className={styles.timeElapsed}>
          <p className="t1 smBld">{currentTimeDisplay}</p>
        </div>
      </div>
    </div>
  );
}
