/* ToastAlert Name */
/* External Imports */
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

/* Local Imports */

// components
import Close from "../../../public/svgs/close_dynamic.svg";
import Icon from "../icons";
// constants

// store
import { removeAlertFromToastTrough } from "./toastSlice";

// styles
import styles from "./styles.module.scss";

/* ToastAlert Typescript Interface */
interface ToastAlertProps {
  message: string;
  type: string;
  id: number;
}

export default function ToastAlert({ message, type, id }: ToastAlertProps) {
  /* Redux */
  const dispatch = useDispatch();
  /* Local State */

  useEffect(() => {
    // if alert is a loading alert, don't remove it
    if (type === "loading") return;
    setTimeout(() => {
      // after 5 seconds, alert is removed from DOM
      dispatch(removeAlertFromToastTrough(id));
    }, 3000);
  }, [type]);

  /* Effects */

  /* Event Handlers */

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, right: -200 }}
        animate={{ opacity: 1, right: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.25 }}
        key={id}
        className={clsx(styles.ToastAlert, styles[type])}
      >
        {type === "success" && <Icon svg={"check_success_outline"} />}
        {type === "error" && <Icon svg={"x_failure_outline"} />}
        {type === "loading" && <div className={styles.spinner} />}

        {message}
        <button onClick={() => dispatch(removeAlertFromToastTrough(id))}>
          <Close width={8} height={8} />
        </button>
      </motion.div>
    </AnimatePresence>
  );
}
