// External
import { useState } from "react";

// Components & Store
import { HealthHistorySection } from "@/store/services/patient";
import { HistoryCard } from "./historyCard";

// Styles
import styles from "./styles.module.scss";

interface HealthHistoryProps {
  patientId: number;
}

export const HEALTH_HISTORY_SECTIONS: Array<HealthHistorySection> = [
  "PROBLEM",
  "MEDICAL",
  "SURGICAL",
  "FAMILY",
  "GYN",
  "SOCIAL",
  "ALLERGY",
  "MEDICATION"
];

export const HEALTH_HISTORY_LABEL: Record<HealthHistorySection, string> = {
  ALLERGY: "Allergies",
  FAMILY: "Family History",
  GYN: "Gyn History",
  SOCIAL: "Social History",
  MEDICAL: "Past Medical History",
  MEDICATION: "Medications",
  PROBLEM: "Problems",
  SURGICAL: "Past Surgical History"
};

export function HealthHistory({ patientId }: HealthHistoryProps) {
  const [openCards, setOpenCards] = useState<Array<number>>([0]);
  const array = [1, 2, 3];

  return (
    <div className={styles.HealthHistory}>
      <div className={styles.header}>
        <h3 className="gray800 smBld">Health History</h3>
        <a
          onClick={() => {
            if (openCards.length > 0) {
              setOpenCards([]);
            } else {
              setOpenCards(array);
            }
          }}
        >
          {openCards.length > 0 ? "Collapse all" : "Expand all"}
        </a>
      </div>
      <div className={styles.historyCards}>
        {HEALTH_HISTORY_SECTIONS.map((val, index) => (
          <HistoryCard
            onOpenToggle={() => {
              if (openCards.includes(index)) {
                setOpenCards(openCards.filter(el => el != index));
              } else {
                setOpenCards([...openCards, index]);
              }
            }}
            isOpen={openCards.includes(index)}
            patientId={patientId}
            section={val}
            key={index}
          />
        ))}
      </div>
    </div>
  );
}
