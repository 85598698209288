/* Button Component */

// External
import clsx from "clsx";
import Image from "next/image";
import { Josefin_Sans } from "next/font/google";

// constants
import { LAYOUTS, PAGES, STYLES } from "@/globals/constants";
// styles
import styles from "./styles.module.scss";

const jo = Josefin_Sans({
  weight: "500",
  subsets: ["latin"],
});

export interface Logo {
  type: string;
}

export default function Logo({ type = STYLES.PRIMARY }: Logo) {
  return (
    <div className={clsx(styles.Logo, styles[type])}>
      <Image
        src={`/svgs/logo_${type}.svg`}
        alt="Pario Health Logo"
        width={40}
        height={40}
      />
      <div className={jo.className}>pario</div>
    </div>
  );
}
