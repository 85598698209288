/* LabFlowsheetEntry Name */
/* External Imports */
import clsx from "clsx";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

/* Local Imports */

// components
import Input from "@/components/input";
import FileViewer from "@/components/fileExplorer/fileViewer";
import Button from "@/components/button";
import BasicAccordion from "@/components/accordions/basic";
import Delete from "../../../../public/svgs/delete_dynamic.svg";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";

// store
import { useFilesQuery } from "@/store/services/file";
import { useListAcogTestsQuery } from "@/store/services/lab";
import {
  LabFlowsheetTest,
  useCreateLabFlowsheetEntryMutation,
  CreateLabFlowsheetEntry,
  LabFlowsheetEntry as Order,
  useUpdateLabFlowsheetEntryMutation,
  useDeleteLabFlowsheetEntryMutation,
  usePregnancyInfoQuery
} from "@/store/services/pregnancy";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// styles
import styles from "../styles.module.scss";
import { setRightPaneOpen } from "@/components/drawer/drawerSlice";

/* LabFlowsheetEntry Typescript Interface */
interface ComponentProps {
  pregnancyId: number;
  fileId: number;
  entry: Order;
}

export default function LabFlowsheetEntry({
  pregnancyId,
  fileId,
  entry
}: ComponentProps) {
  /* Redux */
  const dispatch = useDispatch();
  const { data: files } = useFilesQuery({ fileIds: [fileId] });
  const { data: acogTests } = useListAcogTestsQuery();
  const [createEntry, { isLoading: isCreating }] =
    useCreateLabFlowsheetEntryMutation();
  const [updateEntry, { isLoading: isUpdating }] =
    useUpdateLabFlowsheetEntryMutation();
  const [deleteEntry, { isLoading: isDeleting }] =
    useDeleteLabFlowsheetEntryMutation();
  const { data: pregnancy } = usePregnancyInfoQuery({ pregnancyId });

  /* Local State */
  const [checked, setChecked] = useState<{ [key: string]: boolean }>({}); // to keep track of checked checkboxes

  /* Effects */
  // initialize the checked state with the values from the entry
  useEffect(() => {
    if (entry) {
      const newChecked: { [key: string]: boolean } = {};
      for (const panel of Object.keys(entry.test_results)) {
        for (const test of entry.test_results[panel]) {
          newChecked[test.observation_name] = test.is_abnormal;
        }
      }
      setChecked(newChecked);
    }
  }, [entry]);

  /* Event Handlers */
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.currentTarget;
    const formData = new FormData(form);
    const data = Object.fromEntries(formData.entries());
    const testResults: { [key: string]: LabFlowsheetTest } = {};

    for (const [key, value] of Object.entries(data)) {
      // Ignore the test_date field
      if (key === "test_date") continue;

      // Parse the keys to extract panel, test, and attribute
      const [panel, test, attribute] = key.split("--");

      if (!testResults[panel]) {
        testResults[panel] = [];
      }

      let observation = testResults[panel].find(
        obs => obs.observation_name === test
      );
      if (!observation) {
        observation = {
          observation_name: test,
          value: "",
          unit: "",
          is_abnormal: false
        };
        testResults[panel].push(observation);
      }

      if (attribute === "value") {
        observation.value = value as string;
      } else if (attribute === "units") {
        observation.unit = value as string;
      } else if (attribute === "abnormal") {
        observation.is_abnormal = checked[test]; // using the checked state to determine if the test is abnormal b/c the value is a string via the native form data
      }
    }

    if (entry) {
      const updateLabFlowsheetEntry: Order = {
        entry_id: entry.entry_id,
        source: entry.source,
        test_date: data.test_date as string,
        file_ids: [fileId],
        test_results: testResults
      };

      updateEntry({
        pregnancyId: pregnancyId,
        manualFlowsheetEntryId: entry.entry_id,
        labFlowsheetEntry: updateLabFlowsheetEntry
      })
        .unwrap()
        .then(onSuccess)
        .catch(onError);
      return;
    } else {
      const createLabFlowsheetEntry: CreateLabFlowsheetEntry = {
        test_date: data.test_date as string,
        file_ids: [fileId],
        test_results: testResults
      };

      createEntry({
        pregnancyId: pregnancyId,
        createLabFlowsheetEntry
      })
        .unwrap()
        .then(onSuccess)
        .catch(onError);
    }
  };

  const handleOnClose = () => {
    return;
  };

  const handleDeleteOrder = () => {
    if (entry) {
      deleteEntry({ pregnancyId, manualFlowsheetEntryId: entry.entry_id })
        .unwrap()
        .then(() => {
          dispatch(
            addAlertToToastTrough({
              message: "Lab flowsheet entry deleted",
              type: STATUS_KEYS.SUCCESS
            })
          );
          setRightPaneOpen(false);
        })
        .catch(() => {
          dispatch(
            addAlertToToastTrough({
              message: "Failed to delete lab flowsheet entry",
              type: STATUS_KEYS.ERROR
            })
          );
        });
    }
  };

  const onSuccess = () => {
    dispatch(
      addAlertToToastTrough({
        message: "Lab flowsheet entry saved",
        type: STATUS_KEYS.SUCCESS
      })
    );
  };

  const onError = () => {
    dispatch(
      addAlertToToastTrough({
        message: "Failed to save lab flowsheet entry",
        type: STATUS_KEYS.ERROR
      })
    );
  };

  return (
    <div className={clsx(styles.LabFlowsheetEntry)}>
      <form className={styles.labFlowsheetForm} onSubmit={handleSubmit}>
        {/* date */}
        <div className="flex alignEnd">
          <Input
            type="date"
            label="Date"
            name="test_date"
            id="test_date"
            value={entry?.test_date || dayjs().format("YYYY-MM-DD")}
            required
          />
          <Button type="submit" loading={isCreating || isUpdating}>
            Save Flowsheet Entry
          </Button>
        </div>

        {/* iterate over the labPanels array to build a form */}
        <div className={styles.panels}>
          {acogTests &&
            acogTests?.map((panel, i) => (
              <BasicAccordion title={panel.acog_test_name} key={i}>
                <div className={styles.panel}>
                  {panel.default_observations.map((test, j) => {
                    const observation = entry?.test_results[
                      panel.acog_test_name
                    ]?.find(obs => obs.observation_name === test) || {
                      value: "",
                      unit: "",
                      is_abnormal: false
                    };

                    return (
                      <div key={j} className={styles.observation}>
                        <p className={styles.observationName}>{test}</p>
                        <div className="flex">
                          <Input
                            type="text"
                            label="value"
                            name={`${panel.acog_test_name}--${test}--value`}
                            id={`${panel.acog_test_name}--${test}--value`}
                            value={observation.value}
                          />
                          <Input
                            type="text"
                            label="units"
                            name={`${panel.acog_test_name}--${test}--units`}
                            id={`${panel.acog_test_name}--${test}--units`}
                            value={observation.unit}
                          />
                          <Input
                            type="checkbox"
                            label="Abnormal"
                            name={`${panel.acog_test_name}--${test}--abnormal`}
                            id={`${panel.acog_test_name}--${test}--abnormal`}
                            checked={checked?.[test]}
                            onChange={() =>
                              // @ts-ignore
                              setChecked(prevChecked => ({
                                ...prevChecked,
                                [test]: !prevChecked[test]
                              }))
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </BasicAccordion>
            ))}
          <Button style={STYLES.DELETE} onClick={handleDeleteOrder}>
            <Delete stroke={styles.errorText} width={15} height={17} />
            Delete Entire Flowsheet Entry
          </Button>
        </div>
      </form>

      {files?.[0] && (
        <FileViewer file={files?.[0]} isReadOnly onClose={handleOnClose} />
      )}
    </div>
  );
}
