// external
import clsx from "clsx";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// components
import { GridDataType } from "@/components/datagrid";
import FlowSheet from "../flowSheet/FlowSheet";

// store
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { PatientInfo, Pregnancy } from "@/store/services/patient";
import {
  PrenatalFlowSheetEntry,
  usePregnancyUpsertMutation
} from "@/store/services/pregnancy";
import { useEncounterInfoQuery } from "@/store/services/encounter";

// utils
import { getGestationalAgeObjectAtDate } from "@/globals/helpers";

// constants
import {
  GRID_METRICS,
  FLOW_KEYS,
  STATUS_KEYS,
  PAGES
} from "@/globals/constants";

// styles
import styles from "./styles.module.scss";
import dayjs from "dayjs";
import { convertUtcIntToLocalDatetime } from "../scheduling/calendars/utils";

export interface PrenatalFlowSheetProps {
  patient: PatientInfo;
  pregnancy: Pregnancy;
  isReadOnly?: boolean;
  encounterId?: number;
}
export default function PrenatalFlowSheet({
  patient,
  pregnancy,
  isReadOnly,
  encounterId
}: PrenatalFlowSheetProps) {
  const dispatch = useDispatch();
  const router = useRouter();

  const [updatePregnancy, updatePregnancyRequest] =
    usePregnancyUpsertMutation();
  const { data: encounter } = useEncounterInfoQuery(
    {
      encounterId: encounterId as number
    },
    { skip: !encounterId }
  );

  const [data, setData] = useState<GridDataType>([]);
  const headers: string[] = GRID_METRICS[FLOW_KEYS.PRENATAL];

  // Effects
  useEffect(() => {
    setData(
      pregnancy.flow_sheet.map((entry, i) => ({
        id: i,
        metrics: {
          ...entry,
          date: parseInt(entry.encounter_start ? convertUtcIntToLocalDatetime(entry.encounter_start).format("YYYYMMDD") : dayjs().format("YYYYMMDD")),
        }
      }))
    );
  }, [pregnancy, encounter]);

  useEffect(() => {
    if (updatePregnancyRequest.isError) {
      dispatch(
        addAlertToToastTrough({
          message: "Failed to update prenatal flow sheet",
          type: STATUS_KEYS.ERROR
        })
      );
    }
  }, [updatePregnancyRequest]);

  // Event handlers
  const submit = (formData: { [key: string]: any }) => {
    const newEntry: PrenatalFlowSheetEntry = {
      weeks: formData["weeks"],
      days: formData["days"],
      blood_pressure: formData["blood_pressure"],
      weight: parseInt(formData["weight"]),
      fundal_height: formData["fundal_height"],
      fetal_heart_tones: formData["fetal_heart_tones"],
      fetal_position: formData["fetal_position"],
      fetal_activity: formData["fetal_activity"],
      edema: formData["edema"],
      pulse: parseInt(formData["pulse"]),
      encounter_id: encounterId
    };

    const entryIndex = pregnancy.flow_sheet.findIndex(
      entry => entry.encounter_id == encounterId
    );

    let flowSheet = [...pregnancy.flow_sheet];
    if (entryIndex === -1) {
      flowSheet = [newEntry, ...flowSheet];
    } else {
      flowSheet[entryIndex] = newEntry;
    }

    updatePregnancy({
      patientId: patient.user_id,
      pregnancyUpsertRequest: {
        pregnancy_id: patient.pregnancy?.pregnancy_id,
        flow_sheet: flowSheet
      }
    });

    setData([
      ...flowSheet.map((entry, i) => ({
        id: i,
        metrics: entry
      }))
    ]);
  };

  const defaults: Record<string, any> = {};
  if (
    pregnancy.edd?.estimated_due_date &&
    (encounter?.start || encounter?.appointment?.starts || encounter?.created)
  ) {
    // TODO: Needs refactor
    const gestAgeObj = getGestationalAgeObjectAtDate(
      parseInt(dayjs(pregnancy.edd.estimated_due_date).format("YYYYMMDD")),
      encounter?.start || encounter?.appointment?.starts || encounter?.created
    );
    defaults["weeks"] = gestAgeObj.weeks;
    defaults["days"] = gestAgeObj.days;
  }

  // Sort data by date descending
  data.sort((a, b) => {
    // note that we're assuming ptimes
    return (b.metrics.date as number) - (a.metrics.date as number);
  });

  return (
    <div
      className={clsx(styles.PrenatalFlowSheet)}
      data-cy="prenatal-flowsheet"
    >
      <FlowSheet
        data={data}
        headers={headers}
        title="Prenatal flow sheet"
        isReadOnly={isReadOnly}
        encounterId={encounterId as number}
        submitOnBlur
        onRowSubmit={submit}
        onRowNavigate={encounterId => {
          router.push(`${PAGES.ENCOUNTERS}/${encounterId}`);
        }}
        navigateButtonTitle="View encounter"
        defaultValues={defaults}
      />
    </div>
  );
}
