/* SubmittedStamp Name */
/* External Imports */
import clsx from "clsx";

/* Local Imports */

// components
import Icon from "@/components/icons";
// constants
import { FORMAT } from "@/globals/helpers/formatters";
import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";
// store

// styles
import styles from "./styles.module.scss";
import { UserInfo } from "@/store/services/encounter";

/* SubmittedStamp Typescript Interface */
interface ComponentProps {
  user: UserInfo;
  timestamp: number;
}

export default function SubmittedStamp({ user, timestamp }: ComponentProps) {
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <div className={clsx(styles.SubmittedStamp)}>
      <div className={styles.icon}>
        <Icon svg="check_success_outline" height={16} width={16} />
      </div>
      <p>
        Submitted by {FORMAT.name(user)} on{" "}
        {convertUtcIntToLocalDatetime(timestamp).format("MM/DD/YYYY")} at{" "}
        {convertUtcIntToLocalDatetime(timestamp).format("h:mm a")}
      </p>
    </div>
  );
}
