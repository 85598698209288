/* External Imports */
import clsx from "clsx";

/* Local Imports */

// components
import { OrderResults } from "./orderResults";

// constants

// store
import { EncounterInfo } from "@/store/services/encounter";

// styles
import styles from "@/components/flows/prenatal/styles.module.scss";

interface LabResultsProps {
  encounter: EncounterInfo;
}
export default function LabResults({
  encounter,
}: LabResultsProps) {
  return (
    <div className={clsx(styles.LabResults)}>
      {encounter?.orders?.map((order) => (
        <OrderResults
          key={order.order_id}
          encounter={encounter}
          order={order}
        />
      ))}
    </div>
  );
}
