// External
import { useState } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { Controller, useForm } from "react-hook-form";
import clsx from "clsx";

// Helpers, globals, constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { EVENTS, STAGES } from "@/components/flows/labor/constants";

// Store
import {
  PatientInfo,
  PatientLaborTransferPdfPayload,
  usePatientTransferRecordsPdfMutation
} from "@/store/services/patient";
import {
  EncounterLaborEventUpsertApiArg,
  useEncounterLaborEventUpsertMutation,
} from "@/store/services/encounter";

// Components
import Input from "@/components/input";
import ControlledCombobox from "@/components/input/controlledCombobox";
import Button from "@/components/button";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import FaxContact from "@/components/input/faxContact";

// Styles
import styles from "../styles.module.scss";
import DateTimeStamp from "@/components/flows/_sections/dateTimeStamp";
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";
import { MODAL_TYPES } from "@/components/modal/dispatcher";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

interface TransferDecisionFormProps {
  defaultValues: any;
  patient: PatientInfo;
  encounterId: number;
}

export default function TransferDecisionForm({
  defaultValues,
  patient,
  encounterId
}: TransferDecisionFormProps) {
  const dispatch = useDispatch();

  const [sendTransferData] = usePatientTransferRecordsPdfMutation();
  const [saveLaborEvent] = useEncounterLaborEventUpsertMutation();

  // Internal state
  const [textAreaFocused, setTextAreaFocused] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");

  // Form hook
  const form = useForm({ defaultValues });
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    setValue
  } = form;

  const launchToast = (message: string, type: string) => {
    dispatch(
      addAlertToToastTrough({
        message,
        type
      })
    );
  };

  const saveTransferForm = async (data: any) => {
    const args: EncounterLaborEventUpsertApiArg = {
      encounterId: encounterId,
      stage: STAGES.TRANSFER,
      laborEvent: EVENTS.DECISION,
      laborEventUpdate: {
        event_id: EVENTS.DECISION,
        forms: data
      }
    }

    await saveLaborEvent(args)
      .unwrap()
      .then(_ => {
        launchToast(
          `Transfer recorded.`,
          STATUS_KEYS.SUCCESS
        );
      })
      .catch(() =>
        launchToast(
          `Transfer event not recorded.`,
          STATUS_KEYS.ERROR
        )
      );
  }

  const submit = async (data: any) => {
    await saveTransferForm(data);

    dayjs.extend(timezone);
    if (!data.hospital_contact) {
      setErrorText("Fax number required");
      return;
    }
    // Get the file ids of all checked files
    const fileKeys = Object.keys(data).filter(
      key => data[key] && /^file_/.test(key)
    );
    const fileIds = fileKeys.map(key => parseInt(key.substring(5)));

    // Get the order ids of all checked orders
    const orderKeys = Object.keys(data).filter(
      key => data[key] && /^order_/.test(key)
    );

    const transferPayload = {
      timezone: dayjs.tz.guess().toString(),
      render_or_fax: "render",
      // TODO(Landon): field for this
      subject_of_transfer: data.subject_of_transfer,
      reason_for_transfer: data.transfer_note,
      transportation_mode: data.transport_type,
      patient_status_at_time_of_transport: data.condition,
      assessment: data.assessment,
      recommendation: data.recommendation,
      additional_files: fileIds,
      order_ids: orderKeys,
    } as PatientLaborTransferPdfPayload;

    if (data.start_time) {
      transferPayload.transportation_called_at = dayjs(
        `${data.start_time}`,
        "YYYYMMDDHHmmss"
      ).toISOString();
    }

    if (data.arrived_time) {
      transferPayload.transportation_arrived_at = dayjs(
        `${data.arrived_time}`,
        "YYYYMMDDHHmmss"
      ).toISOString();
    }

    if (data.departed_time) {
      transferPayload.transportation_departed_at = dayjs(
        `${data.departed_time}`,
        "YYYYMMDDHHmmss"
      ).toISOString();
    }

    sendTransferData({
      patientId: patient.user_id,
      patientLaborTransferPdfPayload: transferPayload
    })
      .unwrap()
      .then(res => {
        const byteCharacters = atob(res.content);
        const byteArrays = [];
        for (let i = 0; i < byteCharacters.length; i++) {
          byteArrays.push(byteCharacters.charCodeAt(i));
        }
        const byteArray = new Uint8Array(byteArrays);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const fileUrl = URL.createObjectURL(blob as Blob);
        dispatch(setModalIsOpen(true));
        dispatch(
          setModalContent({
            type: MODAL_TYPES.PREVIEW_TRANSFER,
            props: {
              title: "Transfer.pdf",
              url: fileUrl,
              onSubmit: () => {
                transferPayload.render_or_fax = "fax";
                transferPayload.fax = data.hospital_contact;
                sendTransferData({
                  patientId: patient.user_id,
                  patientLaborTransferPdfPayload: transferPayload
                }).unwrap().then(() => {
                  dispatch(setModalIsOpen(false));
                  dispatch(addAlertToToastTrough({
                    message: "Successfull sent transfer",
                    type: STATUS_KEYS.SUCCESS
                  }))
                });
              }
            }
          })
        );
      });
  };

  return (
    <form
      className={clsx(styles.DecisionForm, styles.LaborForm)}
      onSubmit={handleSubmit(submit)}
    >
      <div className={styles.timestamps}>
        <Controller
          name="decision_to_transfer"
          control={control}
          render={({ field: { onChange, name, value } }) => {
            return (
              <DateTimeStamp
                name={name}
                onChange={onChange}
                initialValue={defaultValues && defaultValues.decision_to_transfer}
                value={value}
                label="Time of decision to transport"
              />
            );
          }}
        />
        <Controller
          name="arrived_time"
          control={control}
          render={({ field: { onChange, name, value } }) => {
            return (
              <DateTimeStamp
                name={name}
                onChange={onChange}
                initialValue={defaultValues && defaultValues.arrived_time}
                value={value}
                label="Time transport arrived"
              />
            );
          }}
        />
        <Controller
          name="departed_time"
          control={control}
          render={({ field: { onChange, name, value } }) => {
            return (
              <DateTimeStamp
                name={name}
                onChange={onChange}
                initialValue={defaultValues && defaultValues.departed_time}
                value={value}
                label="Time transport departed"
              />
            );
          }}
        />
      </div>
      <div>
        <ControlledCombobox
          label="Subject of transfer"
          name="subject_of_transfer"
          control={control}
          errors={errors}
          isHorizontalLayout={false}
          options={["mother", "infant"]}
          fullWidth
          required
        />
        <ControlledCombobox
          label="Type of transport"
          name="transport_type"
          control={control}
          errors={errors}
          isHorizontalLayout={false}
          options={["Ambulance", "Provider's car", "Patient's car"]}
          fullWidth
        />
      </div>
      <div className={styles.fullWidth}>
        <Input
          type="text"
          name="assessment"
          register={register}
          id="assessment"
          label="Assessment"
          fullWidth
        />
        <Input
          type="text"
          name="recommendation"
          id="recommendation"
          label="Recommendation"
          register={register}
          fullWidth
        />
      </div>
      <div className={styles.fullWidth}>
        <ControlledTextArea
          label="Transfer note"
          name="transfer_note"
          id="transfer_note"
          form={form}
          rows={3}
          placeholder="Enter reason for transfer and any other relevant infomation here..."
        />
      </div>
      <ControlledCombobox
        label="Condition at transport"
        name="condition"
        control={control}
        errors={errors}
        isHorizontalLayout={false}
        options={["Stable", "Unstable"]}
        fullWidth
      />
      <FaxContact onChange={item => setValue("hospital_contact", item?.fax)} />
      {/* REMOVING UNTIL BACKEND CAN ATTACH ADDITIONAL FILES
      <div className={styles.fullWidth}>
        <AdditionalDataCheckList patient={patient} form={form} />
      </div> */}

      <div className={styles.fullWidth}>
        <ControlledTextArea
          label="Comments"
          form={form}
          name="comments"
          id="comments"
          placeholder="Enter comments here."
          rows={4}
          onFocus={() => {
            setTextAreaFocused(true);
          }}
          onBlur={() => {
            setTextAreaFocused(false);
          }}
        />
      </div>
      <div className={styles.fullWidth}>
        {(errorText != "" || errors.subject_of_transfer) && (
          <div style={{margin: "10px 0"}}>
            <p className="error" style={{padding: "10px"}}>
              {errors.subject_of_transfer ? "Subject is required" : errorText}
            </p>
          </div>
        )}
        <div className="grid2">
          <Button style={STYLES.FULL_WIDTH} type="button" onClick={() => saveTransferForm(form.getValues())}>
            Save
          </Button>
          <Button style={STYLES.FULL_WIDTH} type="submit" nativeButtonProps={{ disabled: !form.getValues("hospital_contact") }}>
            Preview & Send
          </Button>
        </div>
      </div>
    </form>
  );
}
