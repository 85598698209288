/* MilestoneMarker Name */
/* External Imports */
import clsx from "clsx";
import { useState } from "react";
/* Local Imports */

// components
import Clock from "../../../public/svgs/clock-check.svg";
import Button from "../button";
// constants

import { STYLES } from "@/globals/constants";
// store

// styles
import styles from "./styles.module.scss";

// utils
import { convertUtcIntToLocalDatetime } from "../scheduling/calendars/utils";

/* MilestoneMarker Typescript Interface */
interface MilestoneMarkerProps {
  label: string;
  id: string;
  isSelected: boolean;
  onClick: (id: string) => void;
  highlighted?: boolean;
  timestamp?: number;
  disabled?: boolean;
}

export default function MilestoneMarker({
  label,
  id,
  isSelected = false,
  onClick,
  highlighted = false,
  timestamp,
  disabled = false,
}: MilestoneMarkerProps) {
  /* Redux */

  /* Local State */
  const [selected, setSelected] = useState(false);

  /* Effects */

  /* Event Handlers */

  return (
    <div
      className={clsx(styles.MilestoneMarker, {
        [styles.selected]: isSelected,
        [styles.highlighted]: highlighted,
      })}
      data-cy={`milestone-marker-${id}`}
    >
      {timestamp && (
        <div className={styles.timeStamp}>
          <Clock stroke={styles.primary700} width={16} height={16} />
        </div>
      )}
      <Button style={STYLES.SECONDARY} onClick={() => onClick(id)} nativeButtonProps={{disabled}}>
        {label}
      </Button>
    </div>
  );
}
