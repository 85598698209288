// External
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import clsx from "clsx";

// Globals, constants, helpers
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { EVENTS, STAGES } from "@/components/flows/labor/constants";
import { getTimeElapsed } from "@/utils/api/time";

// Store
import {
  LaborModel,
  PatientInfo,
  PregnancyOutcome,
  usePatientGetInfoQuery,
  usePatientUpsertMutation
} from "@/store/services/patient";
import { usePregnancyUpsertMutation } from "@/store/services/pregnancy";

// Components
import Input from "@/components/input";
import ControlledCombobox from "@/components/input/controlledCombobox";
import Button from "@/components/button";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import KeyValueGrid from "@/components/birthSummary/keyValueGrid";
import KeyValueItem from "@/components/birthSummary/keyValueItem";

// Styles
import styles from "./styles.module.scss";
import dayjs from "dayjs";
import { useEncounterInfoQuery } from "@/store/services/encounter";
import { useLocationGetInfoQuery } from "@/store/services/location";

interface SummaryFormProps {
  defaultValues: any;
  patient: PatientInfo;
  labor?: LaborModel;
  encounterId: number;
  onSubmit: (data: any) => void;
}
export default function SummaryForm({
  defaultValues,
  patient,
  onSubmit,
  encounterId,
  labor
}: SummaryFormProps) {
  const dispatch = useDispatch();
  const router = useRouter();

  const form = useForm({ defaultValues });
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    register
  } = form;

  // API Hooks
  const [updatePregnancy, updatePregnancyRequest] =
    usePregnancyUpsertMutation();
  const [updatePatient] = usePatientUpsertMutation();

  const { data: infantData } = usePatientGetInfoQuery(
    {
      patientId: patient?.pregnancy?.infant_id as number
    },
    {
      skip: !patient || !patient?.pregnancy?.infant_id
    }
  );

  const { data: encounter } = useEncounterInfoQuery(
    { encounterId: encounterId },
    { skip: !encounterId }
  );

  const visitLocation = watch("select_appt_location", encounter?.location);

  const { data: locationInfo } = useLocationGetInfoQuery(
    {
      locationId: visitLocation
    },
    {
      skip: !visitLocation
    }
  );

  const [canViewSummary, setCanViewSummary] = useState<boolean>(false);

  useEffect(() => {
    setValue("delivery_place", locationInfo?.name || "");
  }, [visitLocation]);

  useEffect(() => {
    setDeliveryDate();
  }, [labor, infantData, encounter]);

  // Stop the user from viewing the birth summary if they haven't completed the form.
  // Technically, they can few it at any time via the buttons at the top but I thought
  // this would be a reminder to update the form before moving on. TBD if this is
  // a good idea.
  useEffect(() => {
    if (defaultValues) {
      setCanViewSummary(true);
    }
  }, [defaultValues]);

  // User feedback toasts
  useEffect(() => {
    if (updatePregnancyRequest.isSuccess) {
      dispatch(
        addAlertToToastTrough({
          message: "Patient's pregnancy updated",
          type: STATUS_KEYS.SUCCESS
        })
      );
      setCanViewSummary(true);
    } else if (updatePregnancyRequest.isError) {
      dispatch(
        addAlertToToastTrough({
          message: "Failed to update patient's pregnancy",
          type: STATUS_KEYS.ERROR
        })
      );
    }
  }, [updatePregnancyRequest]);

  const setDeliveryDate = () => {
    let deliveryDate: String = "";
    // First we'll check the labor every
    if (labor?.stages?.labor?.events?.birth) {
      deliveryDate = dayjs(
        `${labor?.stages?.labor?.events?.birth.start}`,
        "YYYYMMDDHHmmss"
      ).format("YYYY-MM-DD");
      setValue("delivery_date", deliveryDate);
      return;
    }
    // Then we'll check the infant's DOB
    if (infantData?.dob) {
      deliveryDate = dayjs(`${infantData.dob}`, "YYYYMMDD").format(
        "YYYY-MM-DD"
      );
      setValue("delivery_date", deliveryDate);
      return;
    }
    // If all else fails, we'll get the date of the encounter
    if (encounter?.created) {
      deliveryDate = dayjs(`${encounter.created}`, "YYYYMMDDHHmmss").format(
        "YYYY-MM-DD"
      );
      setValue("delivery_date", deliveryDate);
    }
  };

  // Handlers
  const onOwnSubmit = (data: any) => {
    updatePregnancy({
      patientId: patient.user_id,
      pregnancyUpsertRequest: {
        pregnancy_id: patient.pregnancy?.pregnancy_id,
        outcome: data.birth_outcome as PregnancyOutcome,
        state: "POSTPARTUM",
        delivery_date: parseInt(dayjs(data.delivery_date).format("YYYYMMDD")),
        comment: data.comment as string
      }
    });
    updatePatient({
      patientUpsertRequest: {
        user_id: patient.user_id,
        practice_data: {
          type: "POSTPARTUM"
        }
      }
    });
    onSubmit(data);
  };

  return (
    <form
      className={clsx(styles.LaborForm, styles.SummaryForm)}
      onSubmit={handleSubmit(onOwnSubmit)}
    >
      <div className={styles.fullWidth}>
        <Input
          type="text"
          name="delivery_place"
          id="delivery_place"
          label="Place of delivery"
          register={register}
        />
        <Input
          type="date"
          name="delivery_date"
          id="delivery_date"
          label="Delivery date"
          required
          error={errors.delivery_date && "required"}
          register={register}
        />
        <ControlledCombobox
          label="Type of delivery"
          name="birth_outcome"
          control={control}
          errors={errors}
          isHorizontalLayout={false}
          options={[
            "TAB (therapeautic abortion)",
            "EAB (elective abortion)",
            "SAB (miscarriage)",
            "SBVD (stillbirth vaginal delivery)",
            "SBCS (stillbirth cesarean delivery)",
            "NSVD (vaginal delivery)",
            "NSVB (vaginal birth)",
            "VAVD (vacuum vaginal delivery)",
            "FAVD (forceps vaginal delivery)",
            "CS (cesarean)",
            "VBAC (vaginal birth after previous cesarean)"
          ]}
          fullWidth
        />
      </div>
      <div className={styles.fullWidth}>
        <ControlledTextArea
          label="Labor summary"
          name="birth_summary"
          id="birth_summary"
          placeholder="Enter birth summary here."
          form={form}
        />
      </div>
      <div className={clsx(styles.summaryButtons)}>
        <Button type="submit" style={STYLES.FULL_WIDTH}>
          Update pregnancy
        </Button>
        <Button
          type="button"
          style={STYLES.FULL_WIDTH}
          nativeButtonProps={{ disabled: !canViewSummary }}
          onClick={() =>
            router.push({
              query: { ...router.query, showBirthSummary: true }
            })
          }
        >
          Go to birth summary
        </Button>
      </div>
    </form>
  );
}
