import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { TimerEvent } from "./index";
import dayjs from "dayjs";
import { LaborEvent } from "@/store/services/patient";

import styles from "./styles.module.scss";

interface StageTimerProps {
  event: TimerEvent;
}

const StageTimer = forwardRef(({ event }: StageTimerProps, ref) => {
  const [startTime, setStartTime] = useState<number>();
  const [endEvent, setEndEvent] = useState<LaborEvent | null>();

  const [elapsedTimeDisplay, setElapsedTimeDisplay] =
    useState<string>("00:00:00");
  const [isDiffError, setIsDiffError] = useState<boolean>(false);

  useEffect(() => {
    updateEvent();
  }, [event]);

  const updateEvent = () => {
    if (!event) {
      return;
    }

    if (event.event && event.event.start) {
      setStartTime(event.event.start);
    } else {
      setStartTime(undefined);
    }

    if (event.endEvents) {
      // Get the end events that have happened
      const startedEndEvents = event.endEvents.filter(event => {
        return event && event.start;
      });
      if (startedEndEvents.length == 0) {
        setEndEvent(null);
        return;
      }
      // Get the event that happened first
      const firstEndEvent = startedEndEvents.reduce((acc, cur) => {
        if (!acc.start || !cur.start) {
          // This shouldn't happen because of the loop above, but this
          // makes typescript happy
          return acc as LaborEvent;
        }

        if (acc.start > cur.start) {
          return cur as LaborEvent;
        } else {
          return acc as LaborEvent;
        }
      }, startedEndEvents[0]);
      setEndEvent(firstEndEvent);
    } else {
      setEndEvent(null);
    }
  };

  const updateTime = () => {
    if (!startTime) {
      setElapsedTimeDisplay("00:00:00");
      return;
    }

    if (endEvent) {
      const endTime = endEvent.start || 0;
      if (endTime < startTime) {
        setIsDiffError(true);
        return;
      }
      setIsDiffError(false);
      const startObject = dayjs(`${startTime}`, "YYYYMMDDHHmmss");
      const endObject = dayjs(`${endTime}`, "YYYYMMDDHHmmss");
      const diffSeconds = Math.abs(startObject.diff(endObject, "second"));

      const timeString = getTimerStringFromSeconds(diffSeconds);

      setElapsedTimeDisplay(timeString);
    } else {
      const startObject = dayjs(`${startTime}`, "YYYYMMDDHHmmss");
      const endObject = dayjs().utc().format("YYYYMMDDHHmmss");
      const diffSeconds = Math.abs(startObject.diff(endObject, "second"));

      const timeString = getTimerStringFromSeconds(diffSeconds);

      setElapsedTimeDisplay(timeString);
    }
  };

  function getTimerStringFromSeconds(diffSeconds: number) {
    const hours = Math.floor(diffSeconds / 3600);
    const minutes = Math.floor((diffSeconds - hours * 3600) / 60);
    const seconds = diffSeconds - hours * 3600 - minutes * 60;

    let timeString = "";
    if (hours < 10) {
      timeString += "0" + hours + ":";
    } else {
      timeString += hours + ":";
    }

    if (minutes < 10) {
      timeString += "0" + minutes + ":";
    } else {
      timeString += minutes + ":";
    }

    if (seconds < 10) {
      timeString += "0" + seconds;
    } else {
      timeString += "" + seconds;
    }
    return timeString;
  }

  useImperativeHandle(ref, () => {
    return {
      intervalUpdate: () => updateTime(),
      updateEvent: () => updateEvent()
    };
  });

  return (
    <div className={styles.timeElapsed} data-cy={"stage-timer-"+event.event?.event_id}>
      {isDiffError ? (
        <p className="t5">
          {endEvent?.event_id} is earlier than {event.event?.event_id} timestamp.
          Please correct
        </p>
      ) : (
        <p className="t1 smBld">{elapsedTimeDisplay}</p>
      )}
    </div>
  );
});

StageTimer.displayName = "StageTimer";

export default StageTimer;
