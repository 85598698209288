import * as generated from "./generated/claim";

const claim = generated.claim.enhanceEndpoints({
  endpoints: {
    submitClaim: {
      invalidatesTags: ["ClaimOutcome", "Claim"]
    },
    getClaimTimeline: {
      providesTags: ["Claim"]
    },
    createClaimComment: {
      invalidatesTags: ["ClaimOutcome", "Claim"]
    },
    markClaimReadyToBill: {
      invalidatesTags: ["ClaimOutcome", "Claim"]
    },
    setClaimDeniedPendingProviderReview: {
      invalidatesTags: ["ClaimOutcome", "Claim"]
    },
    setClaimPatientBalance: {
      invalidatesTags: ["ClaimOutcome", "Claim"]
    },
    requestClaimChanges: {
      invalidatesTags: ["ClaimOutcome", "Claim"]
    },
    acceptClaim: {
      invalidatesTags: ["ClaimOutcome", "Claim"]
    },
    approveClaim: {
      invalidatesTags: ["ClaimOutcome", "Claim"]
    },
    denyClaim: {
      invalidatesTags: ["ClaimOutcome", "Claim"]
    },
    updateClaimOutcome: {
      invalidatesTags: ["ClaimOutcome", "Claim"]
    },
    listClaimOutcomes: {
      providesTags: ["ClaimOutcome"]
    },
    createClaimOutcome: {
      invalidatesTags: ["ClaimOutcome"]
    },
    listClaims: {
      providesTags: ["Claim", "ClaimOutcome"]
    },
    setClaimPartiallyDenied: {
      invalidatesTags: ["ClaimOutcome", "Claim"]
    },
    setClaimRejectedByClearinghouse: {
      invalidatesTags: ["ClaimOutcome", "Claim"]
    },
    setClaimNonBillable: {
      invalidatesTags: ["ClaimOutcome", "Claim"]
    },
    setClaimOnHold: {
      invalidatesTags: ["ClaimOutcome", "Claim"]
    },
    setClaimCorrectedAndResubmitted: {
      invalidatesTags: ["ClaimOutcome", "Claim"]
    },
    setClaimAppealSubmitted: {
      invalidatesTags: ["ClaimOutcome", "Claim"]
    },
    setClaimAtSecondary: {
      invalidatesTags: ["ClaimOutcome", "Claim"]
    },
    uploadClaimEob: {
      invalidatesTags: ["Claim", "ClaimOutcome"]
    },
    uploadClaimHcfa: {
      invalidatesTags: ["Claim", "ClaimOutcome"]
    },
    getClaimNotifications: {
      providesTags: ["Claim", "ClaimOutcome"]
    }
  }
});

export * from "./generated/claim";
export default claim;
export const {
  useSubmitClaimMutation,
  useGetClaimTimelineQuery,
  useCreateClaimCommentMutation,
  useMarkClaimReadyToBillMutation,
  useRequestClaimChangesMutation,
  useAcceptClaimMutation,
  useApproveClaimMutation,
  useDenyClaimMutation,
  useCreateClaimOutcomeMutation,
  useUpdateClaimOutcomeMutation,
  useListClaimOutcomesQuery,
  useLazyListClaimOutcomesQuery,
  useSetClaimPartiallyDeniedMutation,
  useSetClaimDeniedPendingProviderReviewMutation,
  useSetClaimPatientBalanceMutation,
  useSetClaimRejectedByClearinghouseMutation,
  useSetClaimNonBillableMutation,
  useListClaimsQuery,
  useUploadClaimEobMutation,
  useUploadClaimHcfaMutation,
  useGetClaimNotificationsQuery,
  useSetClaimOnHoldMutation,
  useSetClaimCorrectedAndResubmittedMutation,
  useSetClaimAppealSubmittedMutation,
  useSetClaimAtSecondaryMutation
} = claim;
