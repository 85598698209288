/* ToastTrough - Wraps toast alerts */
/* External Imports */
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";

/* Local Imports */

// components
import Toast from "./toast";
// constants

// store
import { RootState } from "@/store/store";
// styles
import styles from "./styles.module.scss";

/* ToastTrough Typescript Interface */
interface ToastTroughProps {}

export default function ToastTrough({}: ToastTroughProps) {
  /* Redux */

  const { alerts } = useSelector((state: RootState) => state.toast);
  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <div className={clsx(styles.ToastTrough)}>
      {alerts?.map((alert, index) => (
        <Toast key={alert.id} {...alert} />
      ))}
    </div>
  );
}
