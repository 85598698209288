/* Full Physical Section */

/* External Imports */
import clsx from "clsx";
import { useDispatch } from "react-redux";

/* Local Imports */

// components
import Button from "@/components/button";
import Icon from "@/components/icons";

// constants
import { STYLES } from "@/globals/constants";

// store
import { setRightPaneOpen } from "@/components/drawer/drawerSlice";

// styles
import styles from "./styles.module.scss";

/* Component Typescript Interface */
interface PhysicalProps {}

export default function Physical() {
  /* Redux */
  const dispatch = useDispatch();

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <div className={clsx(styles.Physical)}>
      <div className={styles.actionType}>
        <div>Conduct Physical Examination</div>
        <Button
          style={STYLES.SECONDARY}
          onClick={() => dispatch(setRightPaneOpen(true))}
        >
          View exam
          <Icon svg="next" width={10} height={10}></Icon>
        </Button>
      </div>
    </div>
  );
}
