/* InteractiveForm Name */
/* External Imports */
import clsx from "clsx";
import AnvilEmbedFrame from "@anvilco/anvil-embed-frame";
import { useDispatch } from "react-redux";

/* Local Imports */

// InteractiveForms

// constants

// store
import { useTaskGeneratePdfMutation } from "@/store/services/task";
import { useLazyFileDetailsQuery } from "@/store/services/file";
import { setRightPaneContent } from "../drawer/drawerSlice";
import { MODAL_TYPES } from "../modal/dispatcher";
import { useTaskUpdateMutation } from "@/store/services/patient";
import { useReportFrontendErrorMutation } from "@/store/services/system";
// styles
import styles from "./styles.module.scss";
import { useMemo } from "react";

/* InteractiveForm Typescript Interface */
interface InteractiveFormProps {
  anvilForgeSlug: string;
  taskId: number;
  anvilSubmissionEid?: string;
}

export default function InteractiveForm({
  anvilForgeSlug,
  taskId,
  anvilSubmissionEid
}: InteractiveFormProps) {
  /* Redux */
  const [generatePDF] = useTaskGeneratePdfMutation();
  const dispatch = useDispatch();
  const [getFileDetails] = useLazyFileDetailsQuery();
  const [updateTask] = useTaskUpdateMutation();
  const [reportFrontendError] = useReportFrontendErrorMutation();

  /* Local State */

  /* Effects */

  /* Memoization */
  const iframeURL = useMemo(() => {
    // if this is a new submission, the anvilSubmissionEid will be undefined
    let url = `https://app.useanvil.com/form/pario-health-inc/${anvilForgeSlug}`;
    // if this is editing an existing submission, the anvilSubmissionEid will be defined
    if (anvilSubmissionEid) {
      url += `/${anvilSubmissionEid}`;
    }
    return url;
  }, [anvilForgeSlug, anvilSubmissionEid]);

  /* Event Handlers */
  const handleEvent = (eventObject: any) => {
    console.log("Received event from Anvil:", eventObject);
    if (eventObject.action === "forgeLoadPage") {
      // update the associated task with the weldDataEid and the submissionEid
      updateTask({
        taskId,
        taskUpdatePayload: {
          file: {
            anvil_submission_eid: eventObject.submissionEid,
            anvil_weld_data_eid: eventObject.weldDataEid,
            anvil_weld_eid: eventObject.weldEid,
            anvil_forge_eid: eventObject.forgeEid,
          }
        }
      })
        .unwrap()
        .catch(error => {
          reportFrontendError({
            reportSystemError: {
              message: `Error updating task with submissionEid: ${eventObject.submissionEid} and weldDataEid ${eventObject.weldDataEid}: ${error.message}`,
              stack_trace: "components.interactiveForms.handleEvent.updateTask"
            }
          });
          console.error(error);
        });
    }
    if (eventObject.action === "weldComplete") {
      generatePDF({
        taskId,
        taskGeneratePdfPayload: { weldDataEid: eventObject.weldDataEid }
      })
        .unwrap()
        .then(fileId => {
          getFileDetails({
            fileId
          })
            .unwrap()
            .then(file => {
              dispatch(
                setRightPaneContent({
                  type: MODAL_TYPES.VIEW_FILE,
                  props: {
                    title: file.name,
                    url: file.url
                  }
                })
              );
            });
        });
    }
  };

  return (
    <div className={clsx(styles.InteractiveForm)}>
      <AnvilEmbedFrame
        iframeURL={iframeURL}
        onEvent={handleEvent}
        className="anvil-embed-frame"
        style={{ border: "none", height: "100vh" }}
        scroll="smooth"
      />
    </div>
  );
}
