/* Annotation Name */
/* External Imports */
import { useState } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { sanitize } from "dompurify";
import dayjs from "dayjs";

/* Local Imports */

// components
import Button from "@/components/button";
import Icon from "@/components/icons";
import AvatarPlaceholder from "@/components/avatarPlaceholder";
import AddAnnotation from "@/components/annotations/addAnnotation";
import ContentRenderer from "@/components/textArea/contentRenderer";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { FORMAT } from "@/globals/helpers/formatters";

// store
import {
  useFileAnnotationDeleteMutation,
  FileAnnotation
} from "@/store/services/file";
import { addAlertToToastTrough } from "../toastTrough/toastSlice";
import { useUserGetInfoQuery } from "@/store/services/user";

// styles
import styles from "./styles.module.scss";

/* Annotation Typescript Interface */
interface AnnotationProps {
  fileAnnotation: FileAnnotation;
}

export default function Annotation({ fileAnnotation }: AnnotationProps) {
  const { annotation_id, author, created, annotation, file_id } =
    fileAnnotation || {};

  /* Redux */
  const dispatch = useDispatch();
  const { data: userInfo } = useUserGetInfoQuery(
    { userId: author },
    { skip: !author }
  );

  const [deleteAnnotation, { isLoading: isDeleting }] =
    useFileAnnotationDeleteMutation();
  /* Local State */
  // annotation open
  const [annotationOpen, setAnnotationOpen] = useState(false);

  /* Effects */

  /* Event Handlers */
  // handle edit annotation
  const handleEditAnnotation = () => {
    setAnnotationOpen(true);
  };

  const handleDeleteAnnotion = () => {
    // delete the annotation
    deleteAnnotation({ fileId: file_id, annotationId: annotation_id })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Annotation Deleted",
            type: STATUS_KEYS.SUCCESS
          })
        );
      })
      .catch(err => {
        dispatch(
          addAlertToToastTrough({
            message: "Failed to delete annotation",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  return (
    <div key={annotation_id} className={clsx(styles.Annotation)}>
      <div className={styles.metaWrapper}>
        <AvatarPlaceholder
          character={
            userInfo ? userInfo?.first_name?.[0] + userInfo?.last_name?.[0] : ""
          }
          userId={userInfo?.user_id}
        />
        <div>
          <p className="t5">{userInfo ? FORMAT.name(userInfo) : "-"}</p>
          <p className="xLight t5">
            {dayjs(created).format("MM/DD/YYYY")} at{" "}
            {dayjs(created).format("HH:mm a")}
          </p>
        </div>
        <div className={styles.buttons}>
          <Button style={STYLES.SECONDARY} onClick={handleEditAnnotation}>
            <Icon svg="edit" />
          </Button>
          <Button style={STYLES.SECONDARY} onClick={handleDeleteAnnotion}>
            <Icon svg="delete" />
          </Button>
        </div>
      </div>

      {/* conditionally open annotation editor */}
      {annotationOpen ? (
        <AddAnnotation
          fileId={file_id}
          content={annotation}
          onCancel={() => setAnnotationOpen(false)}
          onSubmitted={() => setAnnotationOpen(false)}
          annotationId={annotation_id}
          isEditing
        />
      ) : (
        <div className={styles.content}>
          <ContentRenderer content={sanitize(annotation)} />
        </div>
      )}
    </div>
  );
}
