/* Navbar Component */

// External
import Link from 'next/link'

// components
import ButtonLink from "../button/link";
import Logo from "../logo";

// constants
import { LAYOUTS, STYLES } from "@/globals/constants";

// styles
import styles from "./styles.module.scss";

export interface Navbar {
  type?: string;
}

export default function Navbar({ type }: Navbar) {
  return (
    <div className={styles.Navbar}>
      <Link href="/"><Logo type={STYLES.PRIMARY} /></Link>
      { type != LAYOUTS.LOGIN &&
          <ButtonLink style={STYLES.PRIMARY} href="/login">
            Login
          </ButtonLink>
      }
    </div>
  );
}
