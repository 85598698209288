import { backendSlice as api } from "../../../components/api/apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    generateNote: build.mutation<GenerateNoteApiResponse, GenerateNoteApiArg>({
      query: queryArg => ({
        url: `/generate-note`,
        method: "POST",
        body: queryArg.generateNoteRequest
      })
    }),
    generateNormalizedData: build.mutation<
      GenerateNormalizedDataApiResponse,
      GenerateNormalizedDataApiArg
    >({
      query: queryArg => ({
        url: `/generate-normalized_data`,
        method: "POST",
        body: queryArg.generateNormalizedDataRequest
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as ai };
export type GenerateNoteApiResponse =
  /** status 200 The note that was generated from a list of transcription events.
   */ GenerateNoteResponse;
export type GenerateNoteApiArg = {
  /** Request to generate a note from a list of transcription events.
   */
  generateNoteRequest: GenerateNoteRequest;
};
export type GenerateNormalizedDataApiResponse =
  /** status 200 The normalized data extracted from the note.
   */ GenerateNormalizedDataResponse;
export type GenerateNormalizedDataApiArg = {
  /** Request to generate normalized data from a note.
   */
  generateNormalizedDataRequest: GenerateNormalizedDataRequest;
};
export type NoteSection = {
  /** A key identifying a section of a note. */
  key: string;
  /** The section title. */
  title: string;
  /** Content of the note section. */
  text: string;
};
export type NablaNote = {
  /** Sections of the note. */
  sections: NoteSection[];
};
export type GenerateNoteResponse = {
  note: NablaNote;
};
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type TranscriptItem = {
  /** The transcribed text. */
  text: string;
  /** Who said the text in this transcript item. */
  speaker: "doctor" | "patient" | "unspecified";
};
export type GenerateNoteRequest = {
  /** The transcript from which to generate the note. */
  transcript_items: TranscriptItem[];
};
export type NormalizedData = {
  conditions: any;
  family_history: any;
  observations: any;
};
export type GenerateNormalizedDataResponse = {
  normalized_data: NormalizedData;
};
export type GenerateNormalizedDataRequest = {
  note: NablaNote;
  note_locale?: string;
};
export const { useGenerateNoteMutation, useGenerateNormalizedDataMutation } =
  injectedRtkApi;
