import * as generated from "./generated/tag";

const tag = generated.tag.enhanceEndpoints({
  addTagTypes: ["Tags"],
  endpoints: {
    tagsList: {
      providesTags: ["Tags"]
    },
    tagCreate: {
      invalidatesTags: ["Tags"]
    }
  }
});

export * from "./generated/tag";
export default tag;
export const {
  useTagCreateMutation,
  useTagsListQuery,
  useLazyTagsListQuery
} = tag;
