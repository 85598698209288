/* LeftPane Wrapper Component */
import { useRouter } from "next/router";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { Inter } from "next/font/google";
import FocusTrap from "focus-trap-react";

// store
import { setLeftPaneOpen } from "@/components/drawer/drawerSlice";
import { RootState } from "@/store/store";

// components
import Icon from "@/components/icons";
import FacePage from "@/components/facePage";

// constants
import { PAGES } from "@/globals/constants";
import { DRAWER_TYPES } from "./dispatcher";

// styles
import styles from "./styles.module.scss";

const font = Inter({
  subsets: ["latin"]
});

export interface LeftPaneProps {}

export default function LeftPane({}: LeftPaneProps) {
  const router = useRouter();

  /* Redux */
  const isVisible = useSelector(
    (state: RootState) => state.drawer.leftPaneOpen
  );

  const { type, props } = useSelector(
    (state: RootState) => state.drawer.leftPaneContent
  );

  const DRAWER_DISPATCHER = {
    [DRAWER_TYPES.FACEPAGE]: FacePage
  };
  const Content = DRAWER_DISPATCHER[type || ""];
  const dispatch = useDispatch();

  // Local state //
  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    /**
     * If the drawer is open (`isVisible` is true), a 1-second timeout is used to delay
     * changing the `active` state and passing it to the <FocusTrap /> component.
     * This prevents errors during the drawer's transition. <FocusTrap/> requires
     * a visible "focusable" element at all times. Waiting for the transition ensures
     * the element is visible before trapping the focus.
     */
    if (isVisible) {
      setTimeout(() => {
        setActive(true);
      }, 1000);
    } else {
      setActive(false);
    }
  }, [isVisible]);

  return (
    // trap focus inside left pane when drawer is visible (for accessibility)
    <FocusTrap
      active={active}
      focusTrapOptions={{
        allowOutsideClick: true,
        clickOutsideDeactivates: true
      }}
    >
      <div
        className={clsx(styles.LeftPane, font.className, {
          [styles.isVisible]: isVisible
        })}
      >
        <div>
          <nav>
            <button onClick={() => dispatch(setLeftPaneOpen(!isVisible))}>
              <Icon svg="back" height={8} />
            </button>
          </nav>
          {/* @ts-ignore */}
          {type && Content && (
            // @ts-ignore
            <div className={styles.content}>{<Content {...props} />}</div>
          )}
        </div>
      </div>
    </FocusTrap>
  );
}
