/* SuperbillContent Name */
/* External Imports */
import clsx from "clsx";
import { useRouter } from "next/router";
import { useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
/* Local Imports */

// components
import Tag from "@/components/tag";
import ViewHeader from "../viewHeader";
import ComboboxSelect from "../input/combobox";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { FORMAT, nameAndDOB } from "@/globals/helpers/formatters";
import { convertUtcIntToLocalDatetime } from "../scheduling/calendars/utils";

// store
import {
  useCodingIcd10LookupMutation,
  useCodingCptLookupMutation,
  useCodingCptModifierLookupMutation
} from "@/store/services/coding";
import {
  SuperbillStatus,
  useLazyEncounterInfoQuery,
  Address,
  EncounterId
} from "@/store/services/encounter";
import { useSuperbillUpdateMutation } from "@/store/services/superbill";
import { RootState } from "@/store/store";
import { addAlertToToastTrough } from "../toastTrough/toastSlice";
import { usePracticeInfoQuery } from "@/store/services/practice";
import { useLocationGetInfoQuery } from "@/store/services/phrase";

// styles
import styles from "./styles.module.scss";
import Insuranceform from "../forms/insurance/insuranceForm";
import BasicAccordion from "../accordions/basic";
import ClaimOutcome from "../claimOutcome";
import InsuranceReadOnly from "../forms/insurance/insuranceReadOnly";

/* SuperbillContent Typescript Interface */
interface SuperbillContentProps {
  encounterId?: EncounterId;
  isClaimView?: boolean;
}

export default function SuperbillContent({
  encounterId,
  isClaimView
}: SuperbillContentProps) {
  /* Redux */
  const router = useRouter();
  const dispatch = useDispatch();

  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const [getEncounterInfo, { data: encounter }] = useLazyEncounterInfoQuery();

  const [updateSuperbill] = useSuperbillUpdateMutation();

  const [
    lookupIcd10Codes,
    { data: icd10Details = {}, isSuccess: icd10DetailsLoaded }
  ] = useCodingIcd10LookupMutation();
  const [
    lookupCptCodes,
    { data: cptDetails = {}, isSuccess: cptDetailsLoaded }
  ] = useCodingCptLookupMutation();

  const [lookupCptModifiers] = useCodingCptModifierLookupMutation();

  const { data: practiceInfo } = usePracticeInfoQuery({
    practiceId: encounter?.practice_id as number
  });

  const { data: location } = useLocationGetInfoQuery({
    locationId: encounter?.location as number
  });

  // when the router is ready, load the encounter
  /* Effects */
  useEffect(() => {
    if (!encounterId && router.isReady) {
      const urlEncounterId = parseInt(router.query.encounterId as string);
      getEncounterInfo({ encounterId: urlEncounterId });
    }
    if (encounterId) {
      getEncounterInfo({ encounterId });
    }
  }, [router, encounterId]);

  useMemo(() => {
    if (encounter?.diagnoses) {
      lookupIcd10Codes({
        body: Object.keys(encounter.diagnoses).map(key => parseInt(key))
      });

      const cptCodeIDs: Set<number> = new Set();
      for (const diagInfo of Object.values(encounter.diagnoses)) {
        for (const cptID of diagInfo.cpt) {
          cptCodeIDs.add(cptID);
        }
      }

      lookupCptCodes({ body: Array.from(cptCodeIDs) });
      lookupCptModifiers({ body: Array.from(cptCodeIDs) });
    }
  }, [encounter?.diagnoses]);

  /* Event Handlers */

  const handlePrint = () => {
    window.print();
  };

  const handleUpdateStatus = (superbillStatus: SuperbillStatus) => {
    if (!superbillStatus) {
      return;
    }
    const updateSuperbillStatus = async () => {
      // wrap in a try catch
      try {
        await updateSuperbill({
          encounterId: encounter?.encounter_id as number,
          superbillUpdatePayload: {
            status: superbillStatus
          }
        }).unwrap();
        dispatch(
          addAlertToToastTrough({
            message: `Superbill status successfully updated`,
            type: STATUS_KEYS.SUCCESS
          })
        );
      } catch (err) {
        dispatch(
          addAlertToToastTrough({
            message: `Superbill status could not be updated. Please try again later`,
            type: STATUS_KEYS.ERROR
          })
        );
      }
    };

    if (superbillStatus !== encounter?.superbill?.status)
      updateSuperbillStatus();
  };

  return (
    <div className={clsx(styles.SuperbillContent)}>
      {encounter && (
        <div className={styles.contentWrapper}>
          <div className={styles.headerWrapper}>
            <ViewHeader
              title="Superbill"
              noBackground
              subtitle={`Date of service: ${convertUtcIntToLocalDatetime(
                encounter.start ||
                  encounter.appointment?.starts ||
                  (encounter.created as number)
              )?.format("MMMM DD, YYYY")}`}
            />
            {sessionInfo?.is_biller && !isClaimView && (
              <div className={styles.statusSelector}>
                <ComboboxSelect
                  label="Update superbill status"
                  placeholder="Select status"
                  initialValue={encounter.superbill?.status}
                  options={[
                    "APPROVED",
                    "DENIED",
                    "IN_PROGRESS",
                    "REQUIRES_CHANGES",
                    "SUBMITTED"
                  ]}
                  labelAcc={s => s.toLowerCase().replaceAll("_", " ")}
                  onChange={handleUpdateStatus}
                />
              </div>
            )}
          </div>
          {/* patient / practitioner information cards */}
          <div className={styles.cardWrapper}>
            <div className={styles.card}>
              <div className="t3 tMd med">{"Patient"}</div>
              <p className="light">{nameAndDOB(encounter.patient)}</p>
              <p className="light t5">
                {FORMAT.address(encounter?.patient?.address as Address)}
              </p>{" "}
              {/* @ts-ignore */}
              <p className="xLight t5">Phone: {encounter?.patient?.phone}</p>
            </div>
            <div className={styles.card}>
              <div className="t3 tMd med">{"Provider"}</div>
              <p className="light">{FORMAT.name(encounter.provider)}</p>
              <p className="light t5">NPI: {encounter.provider.npi}</p>
            </div>
            <div className={styles.card}>
              <div className="t3 tMd med">{"Practice"}</div>
              <p className="light t4">{practiceInfo?.name}</p>
              <p className="light t5">
                {FORMAT.address(location?.address as Address)}
              </p>
              <br />

              {location?.npi && (
                <p className="light t5">NPI: {location?.npi}</p>
              )}
              {location?.tin && (
                <p className="light t5">TIN: {location?.tin}</p>
              )}
              {location?.clia && (
                <p className="light t5">CLIA: {location?.clia}</p>
              )}
              {practiceInfo?.email && <p className="light t5">Email: {practiceInfo?.email}</p> }
              {practiceInfo?.phone && <p className="light t5">Phone: {practiceInfo?.phone}</p> }
              {practiceInfo?.fax && <p className="light t5">Fax: {practiceInfo?.fax}</p> }
            </div>
          </div>
          {/* diagnoses and services data grid */}
          {encounter.codings ? (
            <div className={clsx(styles.gridWrapper, styles.codings)}>
              <ClaimOutcome
                claimId={encounter.claim_id}
                encounter={encounter}
                isSuperbillView
              />
            </div>
          ) : (
            <div className={styles.gridWrapper}>
              <div>
                <table>
                  <caption className="t3 tMd med">ICD-10</caption>
                  <thead>
                    <tr>
                      <th className="t5 tMd xLight">Code</th>
                      <th className="t5 tMd xLight">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(icd10Details)?.map(([icd10ID, details]) => (
                      <tr key={icd10ID}>
                        <td>
                          <Tag label={details.name} type="infoGrey"></Tag>
                        </td>
                        <td className="t5 tSm dark">{details.description}</td>
                      </tr>
                    ))}
                    {icd10DetailsLoaded &&
                      Object.keys(icd10Details).length == 0 && (
                        <tr>
                          <td>
                            <p className="t5 xLight">No diagnoses recorded</p>
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
              <table>
                <caption className="t3 tMd med">CPT</caption>
                <thead>
                  <tr>
                    <th className="t5 tMd xLight">Code</th>
                    <th className="t5 tMd xLight">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(cptDetails)?.map(([cptID, details]) => (
                    <tr key={cptID}>
                      <td>
                        <Tag label={details.cpt_code} type="infoGrey"></Tag>
                      </td>
                      <td className="t5 tSm dark">{details.description}</td>
                    </tr>
                  ))}
                  {cptDetailsLoaded && Object.keys(cptDetails).length == 0 && (
                    <tr>
                      <td>
                        <p className="t5 xLight">
                          No procedures or services recorded
                        </p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
          {isClaimView && (
            // show patient insurance info in the claim view
            <>
              {["Primary", "Secondary", "Tertiary"].map(insuranceType => (
                <BasicAccordion
                  title={`${insuranceType} Insurance`}
                  style={STYLES.TERTIARY}
                  key={insuranceType}
                >
                  <InsuranceReadOnly
                    patientId={encounter.patient.user_id}
                    type={
                      insuranceType.toUpperCase() as
                        | "PRIMARY"
                        | "SECONDARY"
                        | "TERTIARY"
                    }
                  />
                  ;
                </BasicAccordion>
              ))}
            </>
          )}
          {/* footer */}
          {/* certify */}
          {!isClaimView && (
            <ViewHeader
              title=""
              subtitle=""
              noBackground
              actions={[
                {
                  style: STYLES.SECONDARY,
                  label: "Print",
                  action: handlePrint
                }
              ]}
            />
          )}
        </div>
      )}
    </div>
  );
}
