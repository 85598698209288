/* ViewHeader Name */
/* External Imports */
import clsx from "clsx";

/* Local Imports */

// components
import Button from "@/components/button";
import Tag from "@/components/tag";

// constants
import { Action } from "@/globals/constants/types";
import { TagProps } from "@/components/tag";

// store

// styles
import styles from "./styles.module.scss";

/* ViewHeader Typescript Interface */
interface ViewHeaderProps {
  title: string;
  subtitle: string;
  tag?: TagProps;
  actions?: Action[];
  noBackground?: boolean;
}

export default function ViewHeader({
  title,
  subtitle,
  actions,
  tag,
  noBackground = false,
}: ViewHeaderProps) {
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <div
      className={clsx(styles.ViewHeader, {
        [styles.noBackground]: noBackground,
      })}
    >
      {/* title   */}
      <div>
        <h1 className="tMd dark">{title}</h1>
        <p className="xLight">{subtitle}</p>
      </div>
      {tag && <Tag type={tag.type} label={tag.label} />}

      {/* actions */}
      <div className={styles.actionsWrapper}>
        {actions &&
          actions.map(({ label, style, action, nativeButtonProps }) => (
            <Button
              style={style}
              onClick={action}
              key={label}
              nativeButtonProps={nativeButtonProps}
            >
              {label}
            </Button>
          ))}
      </div>
    </div>
  );
}
