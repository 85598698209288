"use client";

// External
import { useEffect, useState } from "react";
import clsx from "clsx";
import { motion } from "framer-motion";
import { sanitize } from "dompurify";

// Components
import CopilotIcon from "../../../public/svgs/copilot-icon.svg";
import ChevronIcon from "../../../public/svgs/chevron_down_grey.svg";
import Tag from "@/components/tag";

// Store
import { STATUS_KEYS } from "@/globals/constants";
import { useGenerateNormalizedDataMutation } from "@/store/services/ai";
import { EncounterInfo } from "@/store/services/patient";
import { useGetFeatureFlagsQuery } from "@/store/services/system";

// Style
import styles from "./styles.module.scss";
import LoadingSpinner from "../modal/templates/loadingSpinner";

interface CopilotProps {
  encounter: EncounterInfo;
}

type Coding = {
  system: string,
  code: string,
  display: string,
}

export default function Copilot({ encounter }: CopilotProps) {
  const [panelClosed, setPanelClosed] = useState<boolean>(true);
  const [icd10Suggestions, setIcd10Suggestions] = useState<Array<Coding>>([]);
  const [hasNewInfo, setHasNewInfo] = useState<boolean>(false);

  const [generateNormalizedData, generateNormalizedDataResponse] =
    useGenerateNormalizedDataMutation();
  const { data: featureFlags } = useGetFeatureFlagsQuery();

  const list = {
    hidden: { opacity: 0, height: 0 },
    shown: { opacity: 1, height: 590 }
  };
  const item = { hidden: { x: -10, opacity: 0 }, shown: { opacity: 1, x: 0 } };

  useEffect(() => {
    if (!encounter.note || encounter.note === "") {
      setHasNewInfo(false);
      return;
    }

    const formattedNote = {
      sections: [
        {
          key: "ASSESSMENT_AND_PLAN",
          title: "Assessment and Plan",
          // Removes all html tags from the text
          text: sanitize(encounter.note || "", { ALLOWED_TAGS: ["#text"] })
        }
      ]
    };

    generateNormalizedData({
      generateNormalizedDataRequest: {
        note: formattedNote,
        note_locale: "en-US"
      }
    })
      .unwrap()
      .then(response => {
        if (response.normalized_data) {
          setIcd10Suggestions(
            // Leaving this typed as any since the type comes from Nabla and I'm typing
            // the inner part
            response.normalized_data.conditions.map((condition: any) => {
              return condition.coding;
            })
          );
          setHasNewInfo(response.normalized_data.conditions.length > 0);
        } else {
          setHasNewInfo(false);
          setIcd10Suggestions([]);
        }
      })
      .catch(err => {
        console.error(err);
        setIcd10Suggestions([]);
      });
  }, [encounter]);

  return (
    <div
      className={clsx(styles.Copilot, {
        [styles.disabled]: !featureFlags?.nabla_enabled
      })}
    >
      <motion.div
        className={clsx(styles.copilotPanel, { [styles.closed]: panelClosed })}
        animate={panelClosed ? "hidden" : "shown"}
        variants={list}
        transition={{ delayChildren: 0.2, staggerChildren: 0.2 }}
      >
        <div className={styles.header}>
          <h3>Pario Copilot</h3>
          <button type="button" onClick={() => setPanelClosed(true)}>
            <ChevronIcon />
          </button>
        </div>
        <div className={styles.suggestions}>
          <div className={styles.suggestionHeader}>
            <p>ICD-10 Suggestions</p>
          </div>
          {icd10Suggestions.length > 0 ? (
            <ul>
              {icd10Suggestions.map((code: any, index: number) => (
                <>
                  <motion.li variants={item} key={index}>
                    <Tag type={STATUS_KEYS.INFO_GREY} label={code.code} />
                    <p>{code.display}</p>
                  </motion.li>
                </>
              ))}
            </ul>
          ) : (
            <p>
              No ICD10 suggestions currently, try inputting more information
              into the encounter note.
            </p>
          )}
        </div>
      </motion.div>
      <button
        className={clsx(styles.copilotButton, {
          [styles.infoState]: hasNewInfo
        })}
        onClick={() => setPanelClosed(!panelClosed)}
        disabled={generateNormalizedDataResponse.isLoading}
      >
        {generateNormalizedDataResponse.isLoading ? (
          <LoadingSpinner />
        ) : (
          <CopilotIcon />
        )}
      </button>
    </div>
  );
}
