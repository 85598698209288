/* RelatedEncounterTab Name */
/* External Imports */
import clsx from "clsx";

/* Local Imports */

// components
import Icon from "@/components/icons";
import SubmittedStamp from "./submittedStamp";

// constants

// store
import {
  EncounterId,
  EncounterInfo,
  useEncounterInfoQuery
} from "@/store/services/encounter";

// styles
import styles from "./styles.module.scss";
import UnsubmittedStamp from "./unsubmittedStamp";

/* RelatedEncounterTab Typescript Interface */
interface RelatedEncounterTabProps {
  onClick?: () => void;
  isSelected?: boolean;
  isRelated?: boolean;
  title: string;
  encounterId: EncounterId;
}

export default function RelatedEncounterTab({
  isSelected,
  isRelated,
  onClick,
  title,
  encounterId
}: RelatedEncounterTabProps) {
  /* Redux */
  const { data: encounter } = useEncounterInfoQuery({ encounterId });

  /* Local State */

  /* Effects */

  /* Event Handlers */
  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <button
      className={clsx(
        {
          [styles.examTab]: isRelated
        },
        {
          [styles.selected]: isSelected
        }
      )}
      type="button"
      onClick={handleClick}
    >
      <h4 className="dark tMd t2">{title}</h4>
      {encounter &&
        encounter.status === "SUBMITTED" &&
        encounter.submitted &&
        encounter.submitted_by && (
          <SubmittedStamp
            user={encounter.submitted_by}
            timestamp={encounter.submitted}
          />
        )}
      {encounter && encounter.status !== "SUBMITTED" && <UnsubmittedStamp />}
    </button>
  );
}
