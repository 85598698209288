/* LaborFlowSheet Component */

/* External Imports */
import { useDispatch } from "react-redux";
import { useState, useMemo, useCallback, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import clsx from "clsx";
import dayjs from "dayjs";

/* Local Imports */

// components
import Icon from "../icons";
import OptionsMenu from "../optionsMenu";
// constants
import {
  EVENT_LABELS,
  SORT_ORDER,
  EVENTS,
  STAGES,
  LABOR_EVENT_TYPES,
  NEWBORN_EXAM_HEADERS
} from "../flows/labor/constants";
import { METRICS, STATUS_KEYS } from "@/globals/constants";
import { FORMAT } from "@/globals/helpers/formatters";

// utils
import { convertUtcIntToLocalDatetime } from "../scheduling/calendars/utils";
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";
import { MODAL_TYPES } from "../modal/dispatcher";
import { addAlertToToastTrough } from "../toastTrough/toastSlice";

// store

import { Pregnancy } from "@/store/services/patient";

// styles
import styles from "./styles.module.scss";
import ContentRenderer from "../textArea/contentRenderer";
import {
  EncounterInfo,
  LaborEventInfo,
  LaborSheet,
  LaborSheetEventType,
  LaborSheetEvents
} from "@/store/services/encounter";

/* LaborFlowSheet Typescript Interface */

interface LaborFlowSheetProps {
  laborSheet?: LaborSheet | LaborSheetEvents;
  title: string;
  readOnly?: boolean;
  hasAlternateViews?: boolean;
  onDelete: (id: string, type: LaborSheetEventType, stage: string) => void;
  open?: boolean;
  encounter?: EncounterInfo;
}

export default function LaborFlowSheet({
  laborSheet,
  title,
  readOnly = false,
  hasAlternateViews,
  onDelete,
  open = false,
  encounter
}: LaborFlowSheetProps) {
  const tableData = useMemo(() => {
    if (!laborSheet) {
      return [];
    }
    // We no longer care about the keyed organization, only all the values
    const data = Object.values(laborSheet);
    // Flatten so we just have an array of all the events
    let events = data.flat();
    // Filter out conclude labor
    events = events.filter(event => {
      return ![EVENTS.LABOR_CONCLUDED, EVENTS.SUMMARY].includes(event.event_id);
    });
    // Sort from most recent to least recent
    return events.sort((a, b) => {
      const aStartTime = a.start || 0;
      const bStartTime = b.start || 0;
      return bStartTime - aStartTime;
    });
  }, [laborSheet]);

  const dispatch = useDispatch();
  const getHeaders = (d: LaborEventInfo): string[] => {
    let headers: string[] = [];
    if (d.forms) {
      let filteredHeaders = Object.keys(d.forms).filter(
        header =>
          !header.includes("comments") &&
          !header.includes("start_time") &&
          header != "baseline" &&
          header != "baseline_notes" &&
          header != "listening_duration" &&
          header !== "name" &&
          header !== "triage_vitals"
      );
      if (d.type === "PROGRESS_UPDATE") {
        const customSort = (cSort: string[], m: string) =>
          cSort ? cSort.indexOf(m) : -1;

        // Use SORT_ORDER[EVENTS.PROGRESS_UPDATE] to sort filteredHeaders
        headers = filteredHeaders.sort(
          (a, b) =>
            customSort(SORT_ORDER[EVENTS.PROGRESS_UPDATE], a) -
            customSort(SORT_ORDER[EVENTS.PROGRESS_UPDATE], b)
        );
      }
      if (d.event_id == EVENTS.NEWBORN_EXAM) {
        filteredHeaders = NEWBORN_EXAM_HEADERS;
      }
      headers = filteredHeaders;
    }
    return headers;
  };

  /* Redux */

  /* Local State */
  const [isOpen, setIsOpen] = useState(false);
  const [view, setView] = useState("ALL");
  const [openRowIds, setOpenRowIds] = useState<string[]>([]);

  const columns = 12;

  const filteredData = useCallback(
    (type: string) => {
      return tableData?.filter(data => data.type === type);
    },
    [tableData]
  );

  const currentData = useMemo(() => {
    if (view !== "ALL") {
      return filteredData(view);
    }
    return tableData;
  }, [view, tableData]);

  /* Effects */
  // Effect to auto-expand all the progress updates
  useEffect(() => {
    if (!tableData) {
      return;
    }
    let progressRows: Array<string> = [];
    tableData.forEach(row => {
      if (
        row.type === "PROGRESS_UPDATE" ||
        row.type === "INFANT_PROGRESS_UPDATE"
      ) {
        progressRows.push(row.event_id);
      }
    });
    setOpenRowIds([...openRowIds, ...progressRows]);
  }, [tableData]);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  /* Event Handlers */
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleToggleRowOpen = (id: string) => {
    let newOpenRowIds;
    if (openRowIds.includes(id)) {
      newOpenRowIds = openRowIds.filter(rId => rId !== id);
    } else {
      newOpenRowIds = [...openRowIds, id];
    }
    setOpenRowIds(newOpenRowIds);
  };

  const handleDeleteEvent = (
    id: string,
    type: LaborSheetEventType,
    stage: string
  ) => {
    onDelete(id, type, stage);
  };

  const handleEditEvent = (id: string, type: LaborSheetEventType) => {
    const events = tableData?.filter(event => event.event_id == id);
    const editingEvent: LaborEventInfo | null =
      events && events.length > 0 ? events[0] : null;

    if (!editingEvent) {
      console.error("Attempting to edit an event that doesn't exist");
      dispatch(
        addAlertToToastTrough({
          message: "Something went wrong!",
          type: STATUS_KEYS.ERROR
        })
      );
      return;
    }

    const eventType = editingEvent.type;
    const laborStage = editingEvent.stage;

    let tab = "";
    // @ts-ignore
    if (editingEvent.forms?.triage_vitals == "true") {
      tab = "triage";
    }

    switch (eventType) {
      case "PROGRESS_UPDATE":
        dispatch(
          setModalContent({
            type: MODAL_TYPES.EDIT_LABOR_PROGRESS_UPDATE,
            props: {
              selectedStage: laborStage,
              encounter,
              laborEventId: id,
              scope: "progress_update",
              editingEvent,
              title: "Editing progress update",
              fullWidth: true,
              tab: tab
            }
          })
        );
        break;
      case "INFANT_PROGRESS_UPDATE":
        dispatch(
          setModalContent({
            type: MODAL_TYPES.EDIT_LABOR_INFANT_PROGRESS_UPDATE,
            props: {
              selectedStage: laborStage,
              encounter,
              laborEventId: id,
              scope: "infant_progress_update",
              editingEvent,
              title: "Editing infant progress update",
              fullWidth: true
            }
          })
        );
        break;
      case "MEDICATION":
        dispatch(
          setModalContent({
            type: MODAL_TYPES.EDIT_LABOR_MEDICATION,
            props: {
              selectedStage: laborStage,
              encounter,
              laborEventId: id,
              scope: "medication",
              editingEvent,
              title: "Editing medication",
              fullWidth: true
            }
          })
        );
        break;
      case "INFANT_MEDICATION":
        dispatch(
          setModalContent({
            type: MODAL_TYPES.EDIT_LABOR_MEDICATION,
            props: {
              selectedStage: laborStage,
              encounter,
              laborEventId: id,
              scope: "infant_medication",
              editingEvent,
              title: "Editing infant medication",
              fullWidth: true
            }
          })
        );
        break;
      case "RECURRING_EVENT":
        dispatch(
          setModalContent({
            type: MODAL_TYPES.EDIT_LABOR_RECURRING_EVENT,
            props: {
              selectedStage: laborStage,
              encounter,
              laborEventId: id,
              scope: "recurring_event",
              editingEvent,
              title: "Editing recurring event",
              fullWidth: true
            }
          })
        );
        break;
      case "EVENT":
        dispatch(
          setModalContent({
            type: MODAL_TYPES.EDIT_LABOR_MILESTONE_EVENT,
            props: {
              selectedStage: laborStage,
              encounter,
              editingEvent,
              title: "Editing recurring event",
              fullWidth: true
            }
          })
        );
        break;
      case "NOTES":
        dispatch(
          setModalContent({
            type: MODAL_TYPES.EDIT_LABOR_SOAP_NOTE,
            props: {
              selectedStage: laborStage,
              encounter,
              editingEvent,
              title: "Editing SOAP note",
              fullWidth: true,
            }
          })
        )
      default:
        dispatch(
          addAlertToToastTrough({
            message: "Incorrect event type",
            type: STATUS_KEYS.WARNING
          })
        );
    }

    dispatch(setModalIsOpen(true));
  };

  const makeEmptyHeaders = (headersLength: number, tableId: string) => {
    // get number of extra columns we need to create
    const length = columns ? columns - headersLength : 0;

    // create an array from the number
    const iterable = Array.from({ length }, (_, index) => index);
    // return an empty table header cell for each element in array
    return iterable.map(i => <th key={`${tableId}-empty-${i}`} />);
  };

  const makeEmptyCells = (headersLength: number, tableId: string) => {
    // get number of extra columns we need to create
    const length = columns ? columns - headersLength : 0;

    // create an array from the number
    const iterable = Array.from({ length }, (_, index) => index);
    // return an empty table header cell for each element in array
    return iterable.map(i => <td key={`${tableId}-empty-${i}`} />);
  };

  const getDatum = (header: string, tableDatum: any) => {
    const datum = tableDatum.forms[header];
    switch (header) {
      case METRICS.PROVIDER:
        return FORMAT.name(datum);
      case "expiration":
        return datum === "" ? "" : dayjs(datum).format("MM/DD/YYYY");
      case "decision_to_transfer":
      case "departed_time":
      case "arrived_time":
      case "end_time":
        if (datum) {
          return convertUtcIntToLocalDatetime(datum as number).format(
            "hh:mm A MM/DD/YYYY"
          );
        } else {
          return "-";
        }
      case "transfer_note":
      case "soap_note":
      case "birth_summary":
        return datum.replace(/<[^>]*>/g, "");
      default:
        return typeof datum === "string" || typeof datum === "number"
          ? datum
          : "";
    }
  };

  return (
    <div className={clsx(styles.LaborFlowSheet)}>
      <div className={styles.header}>
        <div>
          <button
            onClick={toggleAccordion}
            aria-expanded={isOpen}
            aria-controls={`labor flow sheet accordion content`}
          >
            <Icon
              svg={"chevron_down_grey"}
              width={10}
              height={10}
              flipped={!isOpen}
            />
          </button>
          <h3 className="dark t3 smBld">{title}</h3>
        </div>
        {hasAlternateViews && (
          <OptionsMenu
            options={[
              { id: "ALL", label: "View All Details" },
              { id: "MEDICATION", label: "View Medications" },
              { id: "PROGRESS_UPDATE", label: "View Progress Updates" }
            ]}
            selectedOption={view}
            onClick={setView}
          />
        )}
      </div>
      <AnimatePresence>
        {(readOnly || isOpen) && (
          <motion.div
            className={styles.content}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
          >
            {/* Content of your accordion */}
            {currentData && currentData.length ? (
              currentData.map(tableDatum => (
                <table
                  className={clsx(styles[tableDatum.type])}
                  key={tableDatum.event_id}
                >
                  <thead>
                    <tr>
                      <th>
                        <button
                          onClick={() =>
                            handleToggleRowOpen(tableDatum.event_id)
                          }
                        ></button>
                      </th>
                      <th>
                        <button
                          onClick={() =>
                            handleToggleRowOpen(tableDatum.event_id)
                          }
                        ></button>
                      </th>
                      {getHeaders(tableDatum).map(header => (
                        <th key={header}>
                          <button
                            onClick={() =>
                              handleToggleRowOpen(tableDatum.event_id)
                            }
                          >
                            {header.replaceAll("_", " ")}
                          </button>
                        </th>
                      ))}
                      {/* generate empty headers to align data with other tables in grid*/}
                      {makeEmptyHeaders(
                        getHeaders(tableDatum).length,
                        tableDatum.event_id
                      )}

                      <th className={styles.action}>
                        <button
                          title={"Edit event"}
                          onClick={() =>
                            handleEditEvent(
                              tableDatum.event_id,
                              tableDatum.type
                            )
                          }
                          style={{ marginRight: "4px" }}
                          type="button"
                        >
                          <Icon svg={"edit_gray"} width={16} height={16} />
                        </button>
                        <button
                          title={"Delete event"}
                          onClick={() =>
                            handleDeleteEvent(
                              tableDatum.event_id,
                              tableDatum.type,
                              tableDatum.stage
                            )
                          }
                          type="button"
                        >
                          <Icon svg={"trash_grey"} width={16} height={16} />
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <AnimatePresence>
                      <motion.tr
                        layout
                        className={clsx(styles.row, styles[tableDatum?.type])}
                      >
                        <td>
                          <button
                            onClick={() =>
                              handleToggleRowOpen(tableDatum.event_id)
                            }
                          >
                            <p>
                              {convertUtcIntToLocalDatetime(
                                tableDatum.start as number
                              ).format("hh:mm A")}
                            </p>
                            <p className="t5 xLight">
                              {convertUtcIntToLocalDatetime(
                                tableDatum.start as number
                              ).format("MM/DD/YYYY")}
                            </p>
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={() =>
                              handleToggleRowOpen(tableDatum.event_id)
                            }
                          >
                            {EVENT_LABELS[tableDatum.event_id] ||
                              // @ts-ignore
                              EVENT_LABELS[tableDatum.forms?.name] ||
                              // @ts-ignore
                              tableDatum.forms?.name ||
                              (tableDatum.type === "NOTES" && "Note") ||
                              (tableDatum.type === "RECURRING_EVENT" &&
                                "Hydrotherapy")}
                          </button>
                        </td>
                        {getHeaders(tableDatum).map((header, index) => (
                          <td
                            key={`${tableDatum.event_id}-row-${index}-datum-${index}`}
                          >
                            <button
                              onClick={() =>
                                handleToggleRowOpen(tableDatum.event_id)
                              }
                            >
                              {getDatum(header, tableDatum)}
                            </button>
                          </td>
                        ))}
                        {makeEmptyCells(
                          getHeaders(tableDatum).length,
                          tableDatum.event_id
                        )}
                        {tableDatum.type.includes("INFANT") && (
                          <td className={styles.infantBadge}>
                            <span>Newborn</span>
                          </td>
                        )}
                      </motion.tr>
                    </AnimatePresence>
                    <AnimatePresence>
                      {openRowIds.includes(tableDatum.event_id) && (
                        <>
                          {/* @ts-ignore */}
                          {tableDatum.forms?.comments && (
                            <motion.tr
                              initial={{ height: 0, opacity: 0 }}
                              animate={{ height: "auto", opacity: 1 }}
                              exit={{ height: 0, opacity: 0 }}
                              className={clsx(
                                styles.content,
                                styles[tableDatum?.type]
                              )}
                            >
                              <td className={styles.spanRow}>
                                <span className="xLight">Comments: </span>
                                <ContentRenderer
                                  // @ts-ignore
                                  content={tableDatum.forms.comments}
                                />
                              </td>
                            </motion.tr>
                          )}
                          <motion.tr
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: "auto", opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            className={clsx(
                              styles.content,
                              styles[tableDatum?.type]
                            )}
                          >
                            <td className={styles.spanRow}>
                              <span className="xLight">Authored by: </span>
                              {tableDatum.authors?.map(
                                (author: any) => FORMAT.name(author) + " "
                              )}
                            </td>
                          </motion.tr>
                        </>
                      )}
                    </AnimatePresence>
                  </tbody>
                </table>
              ))
            ) : (
              <div className={styles.emptyState}>
                <p className="dark t3 tMd">No additions to the timeline yet</p>
                <p className="t4 xLight">
                  To add progress update or milestone, select the milestone item
                  from the list above.
                </p>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
