// External
import { useEffect, useState } from "react";

// Components
import Alert from "@/components/alert";
import Button from "@/components/button";
import PlayIcon from "../../../public/svgs/play.svg";
import StopIcon from "../../../public/svgs/stop.svg";
import LoadingIcon from "../../../public/svgs/loading-icon.svg";

// store
import {
  NablaNote,
  TranscriptItem,
  useGenerateNoteMutation
} from "@/store/services/ai";
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { msToTime } from "@/globals/helpers/formatters";
import {
  BACKEND_HOST,
  BACKEND_PORT,
  BACKEND_WEBSOCKET_PROTOCOL
} from "@/globals/constants/environment";

// styles
import styles from "./styles.module.scss";


interface AudioRecorderProps {
  onNoteGeneration: (
    noteContent: Array<string>,
    rawNoteData: NablaNote
  ) => void;
  onTranscriptChange: (transcriptData: Array<Record<string, any>>) => void;
  recordingIsDisabled?: boolean;
}

export default function AudioRecorder({
  onNoteGeneration,
  onTranscriptChange,
  recordingIsDisabled = false
}: AudioRecorderProps) {
  const rawPCM16WorkerName = "raw-pcm-16-worker";

  const [websocket, setWebSocket] = useState<WebSocket>();
  const [pcmWorker, setPcmWorker] = useState<AudioWorkletNode>();
  const [audioContext, setAudioContext] = useState<AudioContext>();
  const [mediaSource, setMediaSource] = useState<MediaStreamAudioSourceNode>();
  const [isMicrophoneError, setIsMicrophoneError] = useState<boolean>(false);

  const [transcriptData, setTranscriptData] = useState<
    Array<Record<string, any>>
  >([]);

  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [recordingTime, setRecordingTime] = useState<number>(0);

  const [
    generateNote,
    { isSuccess: isNoteSuccess, isLoading: isNoteLoading, data }
  ] = useGenerateNoteMutation();

  // Check right when the page loads to see if we have access to the user's microphone
  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((val) => {
      if (val) {
        setIsMicrophoneError(false);
      } else {
        setIsMicrophoneError(true);
      }
    }).catch(e => {
      setIsMicrophoneError(true);
    });
  }, []);

  useEffect(() => {
    if (!websocket) {
      return;
    }

    websocket.onclose = event => {
      cleanUpConnection();
    };

    websocket.onopen = event => {
      startAudioStream();
    };

    websocket.onmessage = message => {
      if (typeof message.data === "string") {
        const data = JSON.parse(message.data);
        if (data.object === "transcript_item") {
          setTranscriptData(oldData => {
            let newData: Record<string, any>[] = [];
            newData = oldData;
            for (let i = 0; i < newData.length; i++) {
              const item = newData[i];
              if (item.id == data.id) {
                newData[i] = data;
                // I add this timestamp object here to trigger a rerender in
                // the components that use this data because React doesn't
                // seem to rerender on deep changes like change an element
                // of an object within an array
                newData = [...newData, { timestamp: data.start_offset_ms }];
                return newData;
              }
            }
            newData = [...newData, data];
            return newData;
          });
        }
      }
    };
  }, [websocket]);

  useEffect(() => {
    if (!data || !data.note || !data.note.sections) {
      return;
    }

    const content = data.note.sections.map((section: any) => {
      let contentStr = `<p><strong>${section.title}</strong></p><ul>`;
      const bulletPoints = section.text.split("\n");
      bulletPoints.forEach((point: string) => {
        if (point.charAt(0) === "-") {
          contentStr += `<li>${point.substring(1)}</li>`;
        } else {
          contentStr += `<li>${point}</li>`;
        }
      });
      contentStr += "</ul>";
      return contentStr;
    });

    onNoteGeneration(content, data.note);
  }, [isNoteSuccess]);

  useEffect(() => {
    onTranscriptChange(transcriptData);
  }, [transcriptData]);

  const startAudioStream = async () => {
    try {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        // Ask authorization to access the microphone
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            deviceId: "default",
            sampleRate: 16000,
            sampleSize: 16,
            channelCount: 1
          },
          video: false
        });

        // Make sure we actually have a stream
        if (stream) {
          setIsMicrophoneError(false);
        } else {
          setIsMicrophoneError(true);
        }
        
        const _audioContext = new AudioContext({ sampleRate: 16000 });
        const url = new URL("rawPcm16Processor.js", import.meta.url);
        await _audioContext.audioWorklet.addModule(url);
        const _pcmWorker = new AudioWorkletNode(
          _audioContext,
          rawPCM16WorkerName,
          {
            outputChannelCount: [1]
          }
        );
        const _mediaSource = _audioContext.createMediaStreamSource(stream);
        _mediaSource.connect(_pcmWorker);

        // pcm post on message
        _pcmWorker.port.onmessage = msg => {
          const pcm16iSamples = msg.data;
          const audioAsBase64String = btoa(
            // @ts-ignore
            String.fromCodePoint(...new Uint8Array(pcm16iSamples.buffer))
          );
          if (websocket?.readyState !== websocket?.OPEN) {
            console.error("Websocket is no longer open");
            return;
          }
          // Send the audio chunk to the websocket cnx
          websocket?.send(
            JSON.stringify({
              object: "audio_chunk",
              payload: audioAsBase64String,
              stream_id: "stream1"
            })
          );
        };

        // pcm start
        _pcmWorker.port.start();

        setIsRecording(true);
        setPcmWorker(_pcmWorker);
        setAudioContext(_audioContext);
        setMediaSource(_mediaSource);
      } else {
        // Set microphone warning
        setIsMicrophoneError(true);
        console.error(
          "Microphone audio stream is not accessible on this browser"
        );
      }
    } catch (e) {
      console.warn(e);
      cleanUpConnection();
      websocket?.close();
      setIsMicrophoneError(true);
    }
  };

  useEffect(() => {
    let interval: number;
    setRecordingTime(0);
    if (isRecording) {
      interval = window.setInterval(() => {
        setRecordingTime(previousTime => previousTime + 1);
      }, 1000);
    }

    return () => {
      if (interval) window.clearInterval(interval);
    };
  }, [isRecording]);

  const startRecording = () => {
    setTranscriptData([]);
    setWebSocket(
      new WebSocket(
        `${BACKEND_WEBSOCKET_PROTOCOL}://${BACKEND_HOST}:${BACKEND_PORT}/api/v1/transcribe`
      )
    );
  };

  const stopRecording = () => {
    if (!websocket || websocket.readyState !== websocket.OPEN) {
      console.log("NO WEBSOCKET TO STOP RECORDING!");
      return;
    }
    websocket.send(
      JSON.stringify({
        object: "end"
      })
    );
    cleanUpConnection();
    websocket.close();

    setIsRecording(false);
  };

  const cleanUpConnection = () => {
    if (!audioContext || audioContext.state === "closed") {
      console.error("Audio context doesn't exist or is already closed");
    } else {
      audioContext.close();
    }

    if (!pcmWorker) {
      console.error("PCM Worker doesn't exist or is already closed");
    } else {
      pcmWorker.port.close();
      pcmWorker.disconnect();
    }

    if (!mediaSource) {
      console.error("Media source doesn't exist or is already closed");
    } else {
      mediaSource.mediaStream.getTracks().forEach(track => track.stop());
      mediaSource.disconnect();
    }
  };

  const handleButtonClick = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const handleGenerateNote = () => {
    if (isRecording) {
      stopRecording();
    }

    const sanitizedData = transcriptData.filter(
      item => item.object == "transcript_item"
    );

    generateNote({
      generateNoteRequest: {
        transcript_items: sanitizedData as TranscriptItem[]
      }
    });
  };

  const isGenerationDisabled = () => {
    if (isNoteLoading || !transcriptData.length) {
      return true;
    }
    return false;
  };

  const handleTestTranscript = () => {
    setTranscriptData([
      {
        object: "transcript_item",
        id: "6f061541-e90d-47d0-a9d0-ea11b690f55b",
        text: "OK",
        speaker: "unspecified",
        start_offset_ms: 2840,
        end_offset_ms: 3560,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "89eb55e7-a29a-4094-be2d-43b83d7c7853",
        text: "I am a",
        speaker: "unspecified",
        start_offset_ms: 4440,
        end_offset_ms: 5300,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "89eb55e7-a29a-4094-be2d-43b83d7c7853",
        text: "I am a pregnant woman",
        speaker: "unspecified",
        start_offset_ms: 4440,
        end_offset_ms: 5690,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "8cfe58c0-8960-43d4-9015-c1922883e4aa",
        text: "I",
        speaker: "unspecified",
        start_offset_ms: 7200,
        end_offset_ms: 7240,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "8cfe58c0-8960-43d4-9015-c1922883e4aa",
        text: "I have",
        speaker: "unspecified",
        start_offset_ms: 7080,
        end_offset_ms: 7800,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "8cfe58c0-8960-43d4-9015-c1922883e4aa",
        text: "I have been",
        speaker: "unspecified",
        start_offset_ms: 7080,
        end_offset_ms: 9130,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "8cfe58c0-8960-43d4-9015-c1922883e4aa",
        text: "I have been barfing",
        speaker: "unspecified",
        start_offset_ms: 7080,
        end_offset_ms: 9480,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "8cfe58c0-8960-43d4-9015-c1922883e4aa",
        text: "I have been barfing every single",
        speaker: "unspecified",
        start_offset_ms: 7080,
        end_offset_ms: 10490,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "8cfe58c0-8960-43d4-9015-c1922883e4aa",
        text: "I have been barfing every single morning",
        speaker: "unspecified",
        start_offset_ms: 7080,
        end_offset_ms: 10850,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "8cfe58c0-8960-43d4-9015-c1922883e4aa",
        text: "I have been barfing every single morning.",
        speaker: "unspecified",
        start_offset_ms: 7080,
        end_offset_ms: 11000,
        is_final: true
      },
      {
        object: "transcript_item",
        id: "6f061541-e90d-47d0-a9d0-ea11b690f55b",
        text: "OK.",
        speaker: "unspecified",
        start_offset_ms: 2840,
        end_offset_ms: 3560,
        is_final: true
      },
      {
        object: "transcript_item",
        id: "da9021ff-9d93-40f3-9a0b-cc957a68e085",
        text: "Uh",
        speaker: "unspecified",
        start_offset_ms: 14130,
        end_offset_ms: 14140,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "da9021ff-9d93-40f3-9a0b-cc957a68e085",
        text: "Uh my legs",
        speaker: "unspecified",
        start_offset_ms: 13160,
        end_offset_ms: 14600,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "da9021ff-9d93-40f3-9a0b-cc957a68e085",
        text: "Uh my legs hurt",
        speaker: "unspecified",
        start_offset_ms: 13160,
        end_offset_ms: 14960,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "da9021ff-9d93-40f3-9a0b-cc957a68e085",
        text: "Uh my legs hurt a lot",
        speaker: "unspecified",
        start_offset_ms: 13160,
        end_offset_ms: 15890,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "89eb55e7-a29a-4094-be2d-43b83d7c7853",
        text: "I am a pregnant woman.",
        speaker: "unspecified",
        start_offset_ms: 4440,
        end_offset_ms: 5920,
        is_final: true
      },
      {
        object: "transcript_item",
        id: "b70daaab-a78f-4646-880a-6b7f375de4cc",
        text: "My",
        speaker: "unspecified",
        start_offset_ms: 18130,
        end_offset_ms: 18690,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "b70daaab-a78f-4646-880a-6b7f375de4cc",
        text: "My feet are",
        speaker: "unspecified",
        start_offset_ms: 18130,
        end_offset_ms: 19180,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "b70daaab-a78f-4646-880a-6b7f375de4cc",
        text: "My feet are swollen a lot",
        speaker: "unspecified",
        start_offset_ms: 18130,
        end_offset_ms: 19610,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "da9021ff-9d93-40f3-9a0b-cc957a68e085",
        text: "Uh, my legs hurt a lot.",
        speaker: "unspecified",
        start_offset_ms: 13160,
        end_offset_ms: 16080,
        is_final: true
      },
      {
        object: "transcript_item",
        id: "b8e1df5a-dc0f-4857-8538-16a174207282",
        text: "Uh what was that",
        speaker: "unspecified",
        start_offset_ms: 21520,
        end_offset_ms: 23930,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "d4d1d5eb-0bab-4751-9e2f-54cb0be5fb6d",
        text: "No",
        speaker: "unspecified",
        start_offset_ms: 27560,
        end_offset_ms: 28040,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "d4d1d5eb-0bab-4751-9e2f-54cb0be5fb6d",
        text: "No surgeries",
        speaker: "unspecified",
        start_offset_ms: 27560,
        end_offset_ms: 28570,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "d4d1d5eb-0bab-4751-9e2f-54cb0be5fb6d",
        text: "No surgeries healthy",
        speaker: "unspecified",
        start_offset_ms: 27560,
        end_offset_ms: 29410,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "b70daaab-a78f-4646-880a-6b7f375de4cc",
        text: "My feet are swollen a lot.",
        speaker: "unspecified",
        start_offset_ms: 18130,
        end_offset_ms: 19850,
        is_final: true
      },
      {
        object: "transcript_item",
        id: "d4d1d5eb-0bab-4751-9e2f-54cb0be5fb6d",
        text: "No surgeries healthy as a",
        speaker: "unspecified",
        start_offset_ms: 27560,
        end_offset_ms: 29820,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "d4d1d5eb-0bab-4751-9e2f-54cb0be5fb6d",
        text: "No surgeries healthy as a horse",
        speaker: "unspecified",
        start_offset_ms: 27560,
        end_offset_ms: 30050,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "d4d1d5eb-0bab-4751-9e2f-54cb0be5fb6d",
        text: "No surgeries healthy as a horse until",
        speaker: "unspecified",
        start_offset_ms: 27560,
        end_offset_ms: 30330,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "d4d1d5eb-0bab-4751-9e2f-54cb0be5fb6d",
        text: "No surgeries healthy as a horse until i got",
        speaker: "unspecified",
        start_offset_ms: 27560,
        end_offset_ms: 30490,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "b8e1df5a-dc0f-4857-8538-16a174207282",
        text: "Uh, what was that?",
        speaker: "unspecified",
        start_offset_ms: 21520,
        end_offset_ms: 24160,
        is_final: true
      },
      {
        object: "transcript_item",
        id: "d4d1d5eb-0bab-4751-9e2f-54cb0be5fb6d",
        text: "No surgeries healthy as a horse until i got pregnant",
        speaker: "unspecified",
        start_offset_ms: 27560,
        end_offset_ms: 30850,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "fdff065a-918e-4e65-9442-78c06ddc784c",
        text: "Now",
        speaker: "unspecified",
        start_offset_ms: 31800,
        end_offset_ms: 32240,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "d44393eb-2bed-416d-be5b-7f4b542c7443",
        text: "Uh",
        speaker: "unspecified",
        start_offset_ms: 38670,
        end_offset_ms: 38690,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "d44393eb-2bed-416d-be5b-7f4b542c7443",
        text: "UH-13",
        speaker: "unspecified",
        start_offset_ms: 38270,
        end_offset_ms: 39170,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "d4d1d5eb-0bab-4751-9e2f-54cb0be5fb6d",
        text: "No surgeries, healthy as a horse until I got pregnant.",
        speaker: "unspecified",
        start_offset_ms: 27560,
        end_offset_ms: 30880,
        is_final: true
      },
      {
        object: "transcript_item",
        id: "d44393eb-2bed-416d-be5b-7f4b542c7443",
        text: "Uh thirteen my",
        speaker: "unspecified",
        start_offset_ms: 38270,
        end_offset_ms: 40050,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "d44393eb-2bed-416d-be5b-7f4b542c7443",
        text: "UH-13 my first",
        speaker: "unspecified",
        start_offset_ms: 38270,
        end_offset_ms: 40290,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "d44393eb-2bed-416d-be5b-7f4b542c7443",
        text: "UH-13 my first menstrual period",
        speaker: "unspecified",
        start_offset_ms: 38270,
        end_offset_ms: 40770,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "d44393eb-2bed-416d-be5b-7f4b542c7443",
        text: "UH-13 my first menstrual period was",
        speaker: "unspecified",
        start_offset_ms: 38270,
        end_offset_ms: 41130,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "d44393eb-2bed-416d-be5b-7f4b542c7443",
        text: "UH-13 my first menstrual period was 13",
        speaker: "unspecified",
        start_offset_ms: 38270,
        end_offset_ms: 41450,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "fdff065a-918e-4e65-9442-78c06ddc784c",
        text: "Now.",
        speaker: "unspecified",
        start_offset_ms: 31800,
        end_offset_ms: 32240,
        is_final: true
      },
      {
        object: "transcript_item",
        id: "572d7131-aa7b-4a49-9671-d3db5d45c571",
        text: "I",
        speaker: "unspecified",
        start_offset_ms: 48880,
        end_offset_ms: 48920,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "572d7131-aa7b-4a49-9671-d3db5d45c571",
        text: "I have",
        speaker: "unspecified",
        start_offset_ms: 47880,
        end_offset_ms: 49490,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "572d7131-aa7b-4a49-9671-d3db5d45c571",
        text: "I have been on",
        speaker: "unspecified",
        start_offset_ms: 47880,
        end_offset_ms: 49850,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "572d7131-aa7b-4a49-9671-d3db5d45c571",
        text: "I have been on birth control",
        speaker: "unspecified",
        start_offset_ms: 47880,
        end_offset_ms: 50530,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "778bfbdf-22d5-4ea7-a4ca-7ebb23e92d8b",
        text: "Since",
        speaker: "unspecified",
        start_offset_ms: 51440,
        end_offset_ms: 52450,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "778bfbdf-22d5-4ea7-a4ca-7ebb23e92d8b",
        text: "Since my first",
        speaker: "unspecified",
        start_offset_ms: 51440,
        end_offset_ms: 52890,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "778bfbdf-22d5-4ea7-a4ca-7ebb23e92d8b",
        text: "Since my first menstrual",
        speaker: "unspecified",
        start_offset_ms: 51440,
        end_offset_ms: 53200,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "778bfbdf-22d5-4ea7-a4ca-7ebb23e92d8b",
        text: "Since my first menstrual period",
        speaker: "unspecified",
        start_offset_ms: 51440,
        end_offset_ms: 53530,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "d44393eb-2bed-416d-be5b-7f4b542c7443",
        text: "Thirteen, my first menstrual period was 13.",
        speaker: "unspecified",
        start_offset_ms: 38270,
        end_offset_ms: 41810,
        is_final: true
      },
      {
        object: "transcript_item",
        id: "2b163e2e-65ca-4ba7-a23c-55c8ad60cc78",
        text: "Until",
        speaker: "unspecified",
        start_offset_ms: 54960,
        end_offset_ms: 55770,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "778bfbdf-22d5-4ea7-a4ca-7ebb23e92d8b",
        text: "Since my first menstrual period.",
        speaker: "unspecified",
        start_offset_ms: 51440,
        end_offset_ms: 53880,
        is_final: true
      },
      {
        object: "transcript_item",
        id: "e5c2c272-92b4-4942-8b85-f6ce8f0187a5",
        text: "A few months",
        speaker: "unspecified",
        start_offset_ms: 57240,
        end_offset_ms: 58000,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "e5c2c272-92b4-4942-8b85-f6ce8f0187a5",
        text: "A few months ago",
        speaker: "unspecified",
        start_offset_ms: 57240,
        end_offset_ms: 58570,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "e5c2c272-92b4-4942-8b85-f6ce8f0187a5",
        text: "A few months ago and then",
        speaker: "unspecified",
        start_offset_ms: 57240,
        end_offset_ms: 59560,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "e5c2c272-92b4-4942-8b85-f6ce8f0187a5",
        text: "A few months ago and then i got pregnant",
        speaker: "unspecified",
        start_offset_ms: 57240,
        end_offset_ms: 60210,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "572d7131-aa7b-4a49-9671-d3db5d45c571",
        text: "I have been on birth control.",
        speaker: "unspecified",
        start_offset_ms: 47880,
        end_offset_ms: 50600,
        is_final: true
      },
      {
        object: "transcript_item",
        id: "2b163e2e-65ca-4ba7-a23c-55c8ad60cc78",
        text: "Until.",
        speaker: "unspecified",
        start_offset_ms: 54960,
        end_offset_ms: 55920,
        is_final: true
      },
      {
        object: "transcript_item",
        id: "a7da767b-fc60-4c08-8e44-34e33fdade8a",
        text: "I",
        speaker: "unspecified",
        start_offset_ms: 66260,
        end_offset_ms: 66300,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "a7da767b-fc60-4c08-8e44-34e33fdade8a",
        text: "I have",
        speaker: "unspecified",
        start_offset_ms: 66060,
        end_offset_ms: 67090,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "a7da767b-fc60-4c08-8e44-34e33fdade8a",
        text: "I have an",
        speaker: "unspecified",
        start_offset_ms: 66060,
        end_offset_ms: 66980,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "a7da767b-fc60-4c08-8e44-34e33fdade8a",
        text: "I have an IUD",
        speaker: "unspecified",
        start_offset_ms: 66060,
        end_offset_ms: 67420,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "e5c2c272-92b4-4942-8b85-f6ce8f0187a5",
        text: "A few months ago and then I got pregnant.",
        speaker: "unspecified",
        start_offset_ms: 57240,
        end_offset_ms: 60600,
        is_final: true
      },
      {
        object: "transcript_item",
        id: "6d09498f-9c7a-4235-8cee-8d2bee3ba9f0",
        text: "Had an",
        speaker: "unspecified",
        start_offset_ms: 69690,
        end_offset_ms: 70250,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "6d09498f-9c7a-4235-8cee-8d2bee3ba9f0",
        text: "Had an IU",
        speaker: "unspecified",
        start_offset_ms: 69690,
        end_offset_ms: 70450,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "6d09498f-9c7a-4235-8cee-8d2bee3ba9f0",
        text: "Had an IUD",
        speaker: "unspecified",
        start_offset_ms: 69690,
        end_offset_ms: 70450,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "a7da767b-fc60-4c08-8e44-34e33fdade8a",
        text: "I have an IUD.",
        speaker: "unspecified",
        start_offset_ms: 66060,
        end_offset_ms: 67420,
        is_final: true
      },
      {
        object: "transcript_item",
        id: "294b6933-5f69-4c3d-b4c9-b78050b04cba",
        text: "I",
        speaker: "unspecified",
        start_offset_ms: 78910,
        end_offset_ms: 78950,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "294b6933-5f69-4c3d-b4c9-b78050b04cba",
        text: "I have",
        speaker: "unspecified",
        start_offset_ms: 78790,
        end_offset_ms: 79690,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "294b6933-5f69-4c3d-b4c9-b78050b04cba",
        text: "I have been",
        speaker: "unspecified",
        start_offset_ms: 78790,
        end_offset_ms: 79870,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "6d09498f-9c7a-4235-8cee-8d2bee3ba9f0",
        text: "Had an IUD.",
        speaker: "unspecified",
        start_offset_ms: 69690,
        end_offset_ms: 70450,
        is_final: true
      },
      {
        object: "transcript_item",
        id: "294b6933-5f69-4c3d-b4c9-b78050b04cba",
        text: "I have been feeling a lot of",
        speaker: "unspecified",
        start_offset_ms: 78790,
        end_offset_ms: 80470,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "294b6933-5f69-4c3d-b4c9-b78050b04cba",
        text: "I have been feeling a lot of fatigue",
        speaker: "unspecified",
        start_offset_ms: 78790,
        end_offset_ms: 80890,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "12dd62a5-94cd-48e6-b22b-f86a699ef396",
        text: "I",
        speaker: "unspecified",
        start_offset_ms: 87760,
        end_offset_ms: 87800,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "12dd62a5-94cd-48e6-b22b-f86a699ef396",
        text: "I take",
        speaker: "unspecified",
        start_offset_ms: 86480,
        end_offset_ms: 88410,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "12dd62a5-94cd-48e6-b22b-f86a699ef396",
        text: "I take ibuprofen",
        speaker: "unspecified",
        start_offset_ms: 86480,
        end_offset_ms: 88890,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "294b6933-5f69-4c3d-b4c9-b78050b04cba",
        text: "I have been feeling a lot of fatigue.",
        speaker: "unspecified",
        start_offset_ms: 78790,
        end_offset_ms: 81150,
        is_final: true
      },
      {
        object: "transcript_item",
        id: "c2617f0a-1491-482f-88a0-9896ef935dad",
        text: "Uh",
        speaker: "unspecified",
        start_offset_ms: 93760,
        end_offset_ms: 93840,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "18f43d45-e25c-45d5-9421-a77bbd06bc98",
        text: "No",
        speaker: "unspecified",
        start_offset_ms: 97000,
        end_offset_ms: 97610,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "18f43d45-e25c-45d5-9421-a77bbd06bc98",
        text: "No no",
        speaker: "unspecified",
        start_offset_ms: 97000,
        end_offset_ms: 97760,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "18f43d45-e25c-45d5-9421-a77bbd06bc98",
        text: "No no other prescriptions",
        speaker: "unspecified",
        start_offset_ms: 97000,
        end_offset_ms: 98450,
        is_final: false
      },
      {
        object: "transcript_item",
        id: "18f43d45-e25c-45d5-9421-a77bbd06bc98",
        text: "Treatment for a broken leg. Prescribed ibuprofen. Did a procedure to fix her broken leg.",
        speaker: "unspecified",
        start_offset_ms: 97000,
        end_offset_ms: 98800,
        is_final: true
      },
      {
        object: "transcript_item",
        id: "c2617f0a-1491-482f-88a0-9896ef935dad",
        text: "Uh.",
        speaker: "unspecified",
        start_offset_ms: 93760,
        end_offset_ms: 93840,
        is_final: true
      }
    ]);
  };

  return (
    <div className={styles.recordingContainer}>
      {isMicrophoneError && (
        <Alert
          type={STATUS_KEYS.WARNING}
          message="No microphone detected for recording"
        />
      )}
      <div className={styles.buttonContainer}>
        <Button
          onClick={handleButtonClick}
          style={STYLES.SECONDARY}
          nativeButtonProps={{
            disabled: recordingIsDisabled || isMicrophoneError
          }}
        >
          {isRecording ? (
            <>
              {msToTime(recordingTime * 1000)}
              <span className={styles.stopIcon}>
                <StopIcon />
              </span>
            </>
          ) : (
            <>
              Start recording{" "}
              <span className={styles.playIcon}>
                <PlayIcon />
              </span>
            </>
          )}
        </Button>
        <Button
          onClick={handleGenerateNote}
          style={STYLES.SECONDARY}
          nativeButtonProps={{ disabled: isGenerationDisabled() }}
        >
          {isNoteLoading ? (
            <>
              <LoadingIcon className={styles.loadingIcon} /> Generating...
            </>
          ) : (
            "Generate Note"
          )}
        </Button>
      </div>
    </div>
  );
}
