/* External Imports */
import { useCallback } from "react";
import { useSelector } from "react-redux";

/* Local Imports */
import { openOrderABN, openOrderResults, openOrderRequisition } from "./utils";

// components
import Accordion from "@/components/accordions/basic";
import { setModalIsOpen, setModalContent } from "@/components/modal/modalSlice";
import { TestResults } from "./testResults";

// constants

// store
import { RootState } from "@/store/store";
import { usePracticeInfoQuery } from "@/store/services/practice";
import { useLabGetOrderLabelsQuery } from "@/store/services/lab";

// styles

// TEMP
import { EncounterInfo, LabOrderInfo } from "@/store/services/encounter";
import { MODAL_TYPES } from "@/components/modal/dispatcher";
import { STATUS_KEYS, STYLES, METRIC_LABELS } from "@/globals/constants";
import Button from "../button";
import { useDispatch } from "react-redux";

interface OrderResultsHeader {
  order: LabOrderInfo;
  encounter: EncounterInfo;
}
function OrderResultsHeader({
  encounter,
  order,
}: OrderResultsHeader) {
  const dispatch = useDispatch();
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const { data: practice } = usePracticeInfoQuery(
    { practiceId: sessionInfo?.practice_id as number },
    { skip: !sessionInfo?.practice_id }
  );
  const { data: labels, isLoading: labelsLoading, isError: labelsError } = useLabGetOrderLabelsQuery(
    { orderId: order.order_id },
    { skip: !order.order_id }
  );

  const printLabels = useCallback(() => {
    if (!labels) return;
    const win = window.open('', '_blank');
    if (win) {
      win.document.open();
      win.document.write(Buffer.from(labels.html, "base64").toString());
      win.document.close();

      win.focus();
      win.onafterprint = () => win.close();
      win.print();
    }
  }, [labels]);

  const openLabOrderModal = () => {
    dispatch(setModalIsOpen(true));
    dispatch(
      setModalContent({
        type: MODAL_TYPES.TEST_SELECTION,
        props: { encounter, order },
      })
    );
  };

  const electronicOrderingSupported = practice?.metadata?.health_gorilla?.accounts?.[order.lab_id]?.enabled;

  return (
    <>
    <Button
        style={STYLES.SECONDARY}
        onClick={(e) => {
          e?.stopPropagation();
          openLabOrderModal();
        }}
      >
        Edit
      </Button>
      <Button
        style={STYLES.SECONDARY}
        onClick={async (e) => {
          e?.stopPropagation();
          await openOrderRequisition(order.order_id);
        }}
        nativeButtonProps={{ disabled: order.status === "NEW" && electronicOrderingSupported }}
      >
        Requisition
      </Button>

      <Button
        style={STYLES.SECONDARY}
        onClick={(e) => {
          e?.stopPropagation();
          printLabels();
        }}
        nativeButtonProps={{
          disabled: order.status === "NEW" ||
            !electronicOrderingSupported ||
            labelsLoading ||
            labelsError ||
            !labels
        }}
      >
        Labels
      </Button>

      <Button
        style={STYLES.SECONDARY}
        onClick={async (e) => {
          e?.stopPropagation();
          await openOrderResults(order.order_id);
        }}
        nativeButtonProps={{
          disabled: order.status !== "FULFILLED"
        }}
      >
        Results
      </Button>
      <Button
        style={STYLES.SECONDARY}
        onClick={async (e) => {
          e?.stopPropagation();
          await openOrderABN(order.order_id);
        }}
        nativeButtonProps={{
          disabled: !order.has_abn,
        }}
      >
        ABN
      </Button>
    </>
  );
}

interface OrderResultsProps {
  order: LabOrderInfo;
  encounter: EncounterInfo;
}
export function OrderResults({
  encounter,
  order,
}: OrderResultsProps) {
  return (
    <Accordion
      disabled={order.tests.length === 0}
      open={order.tests.length > 0}
      title={order.label || "Unlabeled Order"}
      tag={{
        label: METRIC_LABELS[order.status],
        type: {
          "NEW": STATUS_KEYS.INFO,
          "SENT": STATUS_KEYS.INFO,
          "IN_PROGRESS": STATUS_KEYS.INFO,
          "ERROR": STATUS_KEYS.ERROR,
          "FULFILLED": STATUS_KEYS.SUCCESS,
          "CORRECTED": STATUS_KEYS.WARNING,
          "REVIEWED": STATUS_KEYS.SUCCESS,
        }[order.status]
      }}
      count={null}
      customLeftContent={
        <OrderResultsHeader
          encounter={encounter}
          order={order}
        />
      }
    >
      {order.tests.map((test, i) => (
        <TestResults resultID={i} key={test.code} order={order} test={test} />
      ))}
    </Accordion>
  );
}
