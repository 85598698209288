/**
 * Formats a number by inserting dashes every `stride` digits and padding it with leading zeroes.
 * The total length of the formatted string is adjusted to be at least `minLength`, rounded up to the nearest
 * greater multiple of `stride` to ensure consistent formatting.
 *
 * @param {number} number - The number to be formatted.
 * @param {number} stride - The number of digits between each dash in the formatted string.
 * @param {number} minLength - The minimum length of the formatted string, including dashes and leading zeroes.
 *                           It will be rounded up to the nearest greater multiple of `stride` if necessary.
 * @returns {string} The formatted string with dashes inserted every `stride` digits and padded with leading zeroes
 *                   to meet the minimum length requirement.
 *
 * @example
 * // Returns '012-345-678-9'
 * formatNumberWithDashes(123456789, 3, 10);
 */
export function formatNumberWithDashes(number: number, stride: number, minLength: number): string {
    // Adjust 'minLength' to be the nearest greater multiple of 'stride'
    if (minLength % stride !== 0) {
        minLength = Math.ceil(minLength / stride) * stride;
    }

    // Convert the number to a string
    let numStr = number.toString();

    // Pad the number with leading zeroes to ensure it's at least 'minLength' long
    numStr = numStr.padStart(minLength, '0');

    // Reverse the string to start processing from the least significant digit
    numStr = numStr.split('').reverse().join('');

    // Use a regular expression to insert dashes every 'stride' characters
    const regex = new RegExp(`(\\d{${stride}})`, 'g');
    numStr = numStr.replace(regex, '$1-');

    // Reverse the string back to its original order and remove any leading dash
    numStr = numStr.split('').reverse().join('').replace(/^-/, '');

    return numStr;
}
