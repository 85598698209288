/* ClaimTimeline */
/* External Imports */
import clsx from "clsx";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { useMemo, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
/* Local Imports */

// components
import ControlledTextArea from "../textArea/controlledTextArea";
import ControlledCombobox from "../input/controlledCombobox";
import Button from "../button";
import ContentRenderer from "../textArea/contentRenderer";

// constants
import { FORMAT } from "@/globals/helpers/formatters";
import {
  CLAIM_STATUSES,
  STYLES,
  METRICS,
  STATUS_KEYS
} from "@/globals/constants";

// store
import {
  ClaimId,
  useGetClaimTimelineQuery,
  useAcceptClaimMutation,
  useApproveClaimMutation,
  useCreateClaimCommentMutation,
  useMarkClaimReadyToBillMutation,
  useSubmitClaimMutation,
  useRequestClaimChangesMutation,
  useDenyClaimMutation,
  useSetClaimDeniedPendingProviderReviewMutation,
  useSetClaimPatientBalanceMutation,
  useSetClaimPartiallyDeniedMutation,
  useSetClaimRejectedByClearinghouseMutation,
  useSetClaimNonBillableMutation,
  useSetClaimOnHoldMutation,
  useSetClaimAppealSubmittedMutation,
  useSetClaimCorrectedAndResubmittedMutation,
  useSetClaimAtSecondaryMutation
} from "@/store/services/claim";
import { RootState } from "@/store/store";
import { setRightPaneOpen } from "@/components/drawer/drawerSlice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// styles

// Temporary mapping of status to text

const statusToText = {
  CREATED: "Draft created by Pario Billing",
  SUBMITTED: "Sent to clearinghouse",
  IN_REVIEW: "Ready to bill",
  NON_BILLABLE: "Marked Non-billable",
  HOLD: "Marked on hold",
  CHANGES_REQUIRED: "Changes required",
  APPROVED: "Paid in Full",
  ACCEPTED: "Accepted by clearinghouse",
  DENIED: "Denied",
  DENIED_PENDING_PROVIDER_REVIEW: "Denied pending provider review",
  PATIENT_BALANCE: "Patient balance",
  PARTIALLY_DENIED: "Partially Denied pending provider review",
  REJECTED_BY_CLEARINGHOUSE: "Rejected by clearinghouse",
  CORRECTED_AND_RESUBMITTED: "Corrected claim submitted",
  APPEAL_SUBMITTED: "Appeal submitted",
  CLAIM_AT_SECONDARY: "Claim at secondary"
};

const selfServiceOverrides = {
  CREATED: "Draft created and not yet sent to biller",
  IN_REVIEW: "Sent to biller",
  CHANGES_REQUIRED: "Changes required"
};

import styles from "./styles.module.scss";

/* ClaimTimeline Typescript Interface */
interface ClaimTimelineProps {
  claimId: ClaimId;
  claimStatus?: string;
  isSelfService?: boolean;
}

export default function ClaimTimeline({
  claimId,
  claimStatus,
  isSelfService
}: ClaimTimelineProps) {
  /* Redux */
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const { data: claimTimeline } = useGetClaimTimelineQuery({ claimId });
  const [createClaimComment] = useCreateClaimCommentMutation();
  const [markClaimReadyToBill] = useMarkClaimReadyToBillMutation();
  const [submitClaim] = useSubmitClaimMutation();
  const [requestClaimChanges] = useRequestClaimChangesMutation();
  const [approveClaim] = useApproveClaimMutation();
  const [acceptClaim] = useAcceptClaimMutation();
  const [denyClaim] = useDenyClaimMutation();
  const [setClaimDeniedPendingProviderReview] =
    useSetClaimDeniedPendingProviderReviewMutation();
  const [setClaimPatientBalance] = useSetClaimPatientBalanceMutation();
  const [setClaimPartiallyDenied] = useSetClaimPartiallyDeniedMutation();
  const [setClaimRejectedByClearinghouse] =
    useSetClaimRejectedByClearinghouseMutation();
  const [setClaimNonBillable] = useSetClaimNonBillableMutation();
  const [setClaimOnHold] = useSetClaimOnHoldMutation();
  const [setClaimAppealSubmitted] = useSetClaimAppealSubmittedMutation();
  const [setClaimCorrectedAndResubmitted] =
    useSetClaimCorrectedAndResubmittedMutation();
  const [setClaimAtSecondary] = useSetClaimAtSecondaryMutation();
  const form = useForm();
  /* Local State */

  const filteredClaimTimeline = useMemo(() => {
    if (!claimTimeline) return [];
    if (isSelfService) {
      return claimTimeline.filter(
        claim =>
          claim.status !== METRICS.SUBMITTED &&
          claim.status !== METRICS.ACCEPTED
      );
    } else {
      return claimTimeline;
    }
  }, [claimTimeline]);

  /* Effects */

  /* Event Handlers */

  const closeModal = () => {
    dispatch(setRightPaneOpen(false));
    dispatch(
      addAlertToToastTrough({
        message: "Claim updated successfully",
        type: STATUS_KEYS.SUCCESS
      })
    );
  };
  const onSubmit = (data: any) => {
    if (data.select_claim_status) {
      switch (data.select_claim_status) {
        case METRICS.SUBMITTED:
          submitClaim({ submitClaimRequest: { claim_ids: [claimId] } }).then(
            closeModal
          );
          break;
        case METRICS.IN_REVIEW:
          markClaimReadyToBill({ claimId }).then(closeModal);
          break;
        case METRICS.CHANGES_REQUIRED:
          requestClaimChanges({
            claimId,
            createClaimCommentRequest: { comment: data.comment }
          }).then(closeModal);
          break;
        case METRICS.APPROVED:
          approveClaim({ claimId }).then(closeModal);
          break;
        case METRICS.ACCEPTED:
          acceptClaim({ submitClaimRequest: { claim_ids: [claimId] } }).then(
            closeModal
          );
          break;
        case METRICS.DENIED:
          denyClaim({ claimId }).then(closeModal);
          break;
        case METRICS.PARTIALLY_DENIED:
          setClaimPartiallyDenied({ claimId }).then(closeModal);
          break;
        case METRICS.DENIED_PENDING_PROVIDER_REVIEW:
          setClaimDeniedPendingProviderReview({ claimId }).then(closeModal);
          break;
        case METRICS.REJECTED_BY_CLEARINGHOUSE:
          setClaimRejectedByClearinghouse({ claimId }).then(closeModal);
          break;
        case METRICS.PATIENT_BALANCE:
          setClaimPatientBalance({ claimId }).then(closeModal);
          break;
        case METRICS.NON_BILLABLE:
          setClaimNonBillable({ claimId }).then(closeModal);
          break;
        case METRICS.HOLD:
          setClaimOnHold({ claimId }).then(closeModal);
          break;
        case METRICS.CORRECTED_AND_RESUBMITTED:
          setClaimCorrectedAndResubmitted({ claimId }).then(closeModal);
          break;
        case METRICS.APPEAL_SUBMITTED:
          setClaimAppealSubmitted({ claimId }).then(closeModal);
          break;
        case METRICS.CLAIM_AT_SECONDARY:
          setClaimAtSecondary({ claimId }).then(closeModal);
          break;
        default:
          break;
      }
      form.reset();
    }
    if (data.comment) {
      if (
        // two updates include the comment, the rest don't so dispatch comment separately
        ![METRICS.CHANGES_REQUIRED].includes(data.select_claim_status)
      ) {
        createClaimComment({
          claimId,
          createClaimCommentRequest: { comment: data.comment }
        });
      }
      form.reset();
    }
  };

  const handleResubmit = () => {
    markClaimReadyToBill({ claimId }).then(closeModal);
  };

  const handleMarkNonBillable = () => {
    setClaimNonBillable({ claimId }).then(closeModal);
  };

  const filteredClaimStatuses = useMemo(() => {
    if (sessionInfo?.is_biller) {
      return CLAIM_STATUSES.filter(
        status => ![METRICS.NON_BILLABLE].includes(status)
      );
    } else {
      if (
        [
          METRICS.CREATED,
          METRICS.IN_REVIEW,
          METRICS.ACCEPTED,
          METRICS.SUBMITTED,
          METRICS.CHANGES_REQUIRED
        ].includes(claimStatus as string)
      ) {
        return [METRICS.IN_REVIEW, METRICS.HOLD, METRICS.NON_BILLABLE];
      }
      if (claimStatus === METRICS.HOLD) {
        return [METRICS.IN_REVIEW, METRICS.NON_BILLABLE];
      }
      if (claimStatus === METRICS.NON_BILLABLE) {
        return [METRICS.IN_REVIEW];
      }
      if (
        [
          METRICS.DENIED_PENDING_PROVIDER_REVIEW,
          METRICS.REJECTED_BY_CLEARINGHOUSE,
          METRICS.PARTIALLY_DENIED
        ].includes(claimStatus as string)
      ) {
        return [METRICS.IN_REVIEW, METRICS.PATIENT_BALANCE, METRICS.APPROVED];
      }
      if (claimStatus === METRICS.PATIENT_BALANCE) {
        return [METRICS.IN_REVIEW, METRICS.APPROVED];
      }
      return [];
    }
  }, [claimStatus, sessionInfo?.is_biller]);

  return (
    <div className={clsx(styles.ClaimTimeline)}>
      <div
        className={styles.timelineContent}
        data-cy={`claim-timeline-content-${claimId}`}
      >
        {filteredClaimTimeline?.map((claim, index) => (
          <Fragment key={claim.at}>
            {/* if its the first entry or first entry of a new day, add date header */}
            {(index === 0 ||
              !dayjs(filteredClaimTimeline[index - 1]?.at).isSame(
                claim.at,
                "day"
              )) && (
              <div className="gray400 flex center">
                <div className="horizontal" />
                {dayjs(claim.at).format("MM/DD/YYYY")}
                <div className="horizontal" />
              </div>
            )}
            <div
              key={claim.at}
              className={clsx(styles.timelineEntry)}
              data-cy={`claim-timeline-entry-${claimId}-${claim.status}`}
            >
              <p className="gray400">{dayjs(claim.at).format("h:mma")}</p>

              <div className={clsx(styles.timeLineInfo)}>
                <div
                  className={clsx(
                    styles.timelineCard,
                    styles[claim.status || ""],
                    {
                      [styles.noComment]: !claim.comment
                    }
                  )}
                >
                  <div className={styles.title}>
                    {/* @ts-ignore */}
                    {isSelfService
                      ? selfServiceOverrides[
                          claim.status as keyof typeof selfServiceOverrides
                        ] ||
                        statusToText[
                          claim.status as keyof typeof statusToText
                        ] ||
                        "Comment"
                      : statusToText[
                          claim.status as keyof typeof statusToText
                        ] ||
                        claim.status ||
                        "Comment"}
                  </div>
                  {claim.comment && (
                    <div className={styles.comment}>
                      <ContentRenderer content={claim.comment} />
                    </div>
                  )}
                </div>
                <p className="gray700 t5 tMd">
                  {FORMAT.name({
                    first_name: claim.creator_first_name,
                    last_name: claim.creator_last_name
                  })}
                </p>
              </div>
            </div>
          </Fragment>
        ))}
      </div>
      {false ? (
        <>
          {claimStatus !== METRICS.NON_BILLABLE && (
            <Button
              style={STYLES.SECONDARY_FULL}
              onClick={handleMarkNonBillable}
            >
              Mark Non-Billable
            </Button>
          )}
          <Button
            type="submit"
            style={STYLES.FULL_WIDTH}
            onClick={handleResubmit}
          >
            Submit
          </Button>
        </>
      ) : (
        <form className="flexCol" onSubmit={form.handleSubmit(onSubmit)}>
          {claimStatus === METRICS.NON_BILLABLE ? (
            <Button
              type="submit"
              style={STYLES.SECONDARY_FULL}
              onClick={handleResubmit}
            >
              Reactivate claim and submit
            </Button>
          ) : (
            // claim is outstanding
            (sessionInfo?.is_biller ||
              ![
                METRICS.ACCEPTED,
                METRICS.CORRECTED_AND_RESUBMITTED,
                METRICS.APPEAL_SUBMITTED,
                METRICS.CLAIM_AT_SECONDARY
              ].includes(claimStatus as string)) && (
              <ControlledCombobox
                control={form.control}
                label="Mark As"
                name="select_claim_status"
                options={filteredClaimStatuses} // here is where i want the claim options to be
                isHorizontalLayout={false}
              />
            )
          )}
          <ControlledTextArea
            form={form}
            label="Enter a comment"
            name="comment"
            id="comment"
            placeholder="Enter a comment"
            hiddenLabel
            rows={3}
          />
          <div data-cy="submit-claim-timeline-entry">
            <Button type="submit" style={STYLES.FULL_WIDTH}>
              Save
            </Button>
          </div>
        </form>
      )}
    </div>
  );
}
