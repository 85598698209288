// External
import { MouseEvent, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";

// Store
import {
  HealthHistoryEntry,
  useUpdateHealthHistoryEntryMutation
} from "@/store/services/patient";

// Components
import Plus from "../../../public/svgs/plus.svg";
import Dots from "../../../public/svgs/dots-horizontal.svg";

// Styles
import styles from "./styles.module.scss";

interface HealthHistoryEntryItemProps {
  entry: HealthHistoryEntry;
  isEditing: boolean;
  onEntryClick: (entry: HealthHistoryEntry) => void;
  onOptionsClick: (entry: HealthHistoryEntry) => void;
  onEditSubmit: () => void;
  patientId: number;
}

const item = {
  hidden: { x: -10, opacity: 0 },
  shown: { x: 0, opacity: 1 }
};

const input = {
  hidden: { width: 0, border: "none", borderColor: "#d0d5dd" },
  shown: { width: "auto", borderBottom: "solid 1px #d0d5dd" }
};

export function HealthHistoryEntryItem({
  entry,
  isEditing,
  onEntryClick,
  onOptionsClick,
  onEditSubmit,
  patientId
}: HealthHistoryEntryItemProps) {
  const [entryText, setEntryText] = useState<string>(entry.title);

  const [updateEntry, updateEntryResponse] =
    useUpdateHealthHistoryEntryMutation();

  const inputRef = useRef<HTMLInputElement>(null);

  const onEditEntry = () => {
    onEditSubmit();
    updateEntry({
      patientId: patientId,
      healthHistoryId: entry.id,
      patientHealthHistoryUpdate: {
        title: entryText
      }
    });
  };

  const handleOptionsClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
    onOptionsClick(entry);
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  }, [isEditing])

  return (
    <motion.li
      className={styles.historyEntry}
      variants={item}
      key={entry.id}
      onClick={() => {
        if (!isEditing) {
          onEntryClick(entry);
        }
      }}
    >
      {updateEntryResponse.isLoading ? (
        <Skeleton height={36} />
      ) : !isEditing ? (
        <>
          <p className={styles.title}>{entry.title}</p>
          <p className={styles.date}>
            {dayjs(entry.created_at).format("DD MMM")}
          </p>
          <a className={styles.options} onClick={handleOptionsClick}>
            <Dots />
          </a>
        </>
      ) : (
        <div className={styles.editEntryContainer}>
          <motion.div
            className={styles.inputContainer}
            variants={input}
            animate={isEditing ? "shown" : "hidden"}
          >
            <input
              type="text"
              placeholder="New entry"
              value={entryText}
              onChange={e => setEntryText(e.target.value)}
              ref={inputRef}
              onKeyDown={(e) => {  
                if (e.key === "Enter") {
                  // Stops it from refreshing the page
                  e.preventDefault();
                  onEditEntry();
                }
              }}
            />
          </motion.div>
          <button className={styles.newEntry} onClick={onEditEntry}>
            <Plus />
          </button>
        </div>
      )}
    </motion.li>
  );
}
