/* LabResults Name */
/* External Imports */
import clsx from "clsx";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import { useSelector, useDispatch } from "react-redux";
/* Local Imports */

// components
import { TestResults } from "@/components/labOrdering/testResults";
import { LabOrderInfo } from "@/store/services/patient";
import Tag from "@/components/tag";
import Button from "@/components/button";
import Icon from "@/components/icons";

// constants
import { PAGES, STATUS_KEYS, STYLES } from "@/globals/constants";

// store
import { RootState } from "@/store/store";
import {
  useLabToggleOrderReviewedMutation,
  useLazyLabGetOrderLabelsQuery
} from "@/store/services/lab";
import { usePracticeInfoQuery } from "@/store/services/practice";

// styles
import styles from "../styles.module.scss";
import {
  openOrderRequisition,
  openOrderResults
} from "@/components/labOrdering/utils";
import { setRightPaneOpen } from "@/components/drawer/drawerSlice";

/* LabResults Typescript Interface */
interface LabResultsProps {
  order: LabOrderInfo;
}

export default function LabResults({ order }: LabResultsProps) {
  /* Redux */
  const dispatch = useDispatch();
  const router = useRouter();
  const [toggleOrderReviewed] = useLabToggleOrderReviewedMutation();
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const { data: practice } = usePracticeInfoQuery(
    { practiceId: sessionInfo?.practice_id as number },
    { skip: !sessionInfo?.practice_id }
  );
  const [getLabels] = useLazyLabGetOrderLabelsQuery();

  /* Local State */
  const electronicOrderingSupported =
    practice?.metadata?.health_gorilla?.accounts?.[order.lab_id]?.enabled;

  /* Effects */

  /* Event Handlers */
  const printLabels = async () => {
    try {
      const labels = await getLabels({ orderId: order.order_id }).unwrap();
      const win = window.open("", "_blank");
      if (win) {
        win.document.open();
        win.document.write(Buffer.from(labels.html, "base64").toString());
        win.document.close();

        win.focus();
        win.onafterprint = () => win.close();
        win.print();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleToggleOrderReviewed = () => {
    toggleOrderReviewed({ orderId: order.order_id });
  };

  const handleViewRequisition = () => {
    openOrderRequisition(order.order_id);
  };

  const handleViewResults = () => {
    openOrderResults(order.order_id);
  };

  const handleViewEncounter = () => {
    // route to encounter associated with this order
    router.push(`${PAGES.ENCOUNTERS}/${order.encounter_id}`);
    dispatch(setRightPaneOpen(false));
  };

  return (
    <div className={clsx(styles.LabResults)}>
      <div className={styles.orderInfo}>
        <div className={styles.orderHeader}>
          <h2>{order.label}</h2>
          <div className={styles.testMetadata}>
            <div className="flex">
              <button
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(order.order_id as string);
                  alert("Copied full ID " + order.order_id + " to clipboard");
                }}
                title={"Click to copy full ID to clipboard"}
              >
                <Tag
                  label={"Order ID | " + (order.order_id as string).slice(-5)}
                  type={STATUS_KEYS.INFO_GREY}
                />
              </button>
              <Tag
                label={
                  order.fulfilled
                    ? `Fulfilled | ${dayjs(order.fulfilled).format(
                        "MM/DD/YYYY hh:mm A"
                      )}`
                    : "Pending"
                }
                type={STATUS_KEYS.INFO_GREY}
              />
              <Tag
                label={order.reviewed ? "Reviewed" : "Not Reviewed"}
                type={order.reviewed ? STATUS_KEYS.SUCCESS : STATUS_KEYS.ERROR}
                customIconSvgPath={
                  order.reviewed ? "check_success_outline" : "x_failure_outline"
                }
              />
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              style={order.reviewed ? STYLES.DELETE : STYLES.PRIMARY}
              onClick={handleToggleOrderReviewed}
            >
              <Icon
                svg={order.reviewed ? "x_failure_outline" : "check-done-wh"}
              />
              {order.reviewed ? "Mark Unreviewed" : "Mark Reviewed"}
            </Button>
            <Button
              style={STYLES.SECONDARY}
              onClick={e => {
                e?.stopPropagation();
                printLabels();
              }}
              nativeButtonProps={{
                disabled: order.status === "NEW" || !electronicOrderingSupported
              }}
            >
              Print Labels
            </Button>
            <Button style={STYLES.SECONDARY} onClick={handleViewRequisition}>
              <Icon svg="eye" />
              View Requisition
            </Button>
            <Button
              style={STYLES.SECONDARY}
              onClick={handleViewResults}
              nativeButtonProps={{
                disabled: ![
                  "FULFILLED",
                  "IN_PROGRESS",
                  "REVIEWED",
                  "CORRECTED"
                ].includes(order.status)
              }}
            >
              <Icon svg="eye" />
              View Results
            </Button>
            <Button style={STYLES.SECONDARY} onClick={handleViewEncounter}>
              <Icon svg="encounter" />
              Encounter {"->"}
            </Button>
          </div>
        </div>
      </div>
      {order.tests?.map((test, i) => (
        <TestResults resultID={i} key={test.code} order={order} test={test} />
      ))}
    </div>
  );
}
