import AssessmentPlan from "@/components/flows/_sections/assessmentPlan";
import Physical from "@/components/flows/_sections/physical";
import Vitals from "@/components/flows/_sections/vitals";
import EncounterNote from "@/components/flows/_sections/note";

import { APP_KEYS, FLOW_KEYS, METRICS } from "@/globals/constants";

const SECTION_TYPES = {
  AP: "Assessment & Plan",
  HH: "Health History",
  PE: "Full PE",
  VT: "Vitals",
  EN: "Encounter Note",
};

const SECTION_CONTENT = {
  [SECTION_TYPES.AP]: AssessmentPlan,
  [SECTION_TYPES.PE]: Physical,
  [SECTION_TYPES.EN]: EncounterNote,
  [SECTION_TYPES.VT]: Vitals,
};

const SECTIONS = {
  [METRICS.WELL_WOMAN]: [
    SECTION_TYPES.VT,
    SECTION_TYPES.PE,
    SECTION_TYPES.EN,
  ],
};

export { SECTION_TYPES, SECTION_CONTENT, SECTIONS, FLOW_KEYS };
