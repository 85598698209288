/* RightPane Wrapper Component */

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import FocusTrap from "focus-trap-react";

// constants

// store
import {
  setRightPaneContent,
  setRightPaneOpen
} from "@/components/drawer/drawerSlice";
import { RootState } from "@/store/store";

// components
import Button from "../button";

// icons
import Close from "../../../public/svgs/close_dynamic.svg";

// styles
import styles from "./styles.module.scss";
import { DRAWER_DISPATCHER } from "./dispatcher";

export interface RightPaneProps {}

export default function RightPane({}: RightPaneProps) {
  /* Redux */
  const isVisible = useSelector(
    (state: RootState) => state.drawer.rightPaneOpen
  );

  const dispatch = useDispatch();

  const { type, props } = useSelector(
    (state: RootState) => state.drawer.rightPaneContent
  );

  // Local state //
  const [active, setActive] = useState<boolean>(false);

  const handleClose = () => {
    dispatch(setRightPaneOpen(false));
    dispatch(setRightPaneContent({ type: null }));
  };

  useEffect(() => {
    const handleTransitionEnd = () => setActive(isVisible);

    const paneElement = document.querySelector(`.${styles.RightPane}`);
    if (paneElement) {
      paneElement.addEventListener("transitionend", handleTransitionEnd);
    }

    return () => {
      if (paneElement) {
        paneElement.removeEventListener("transitionend", handleTransitionEnd);
      }
    };
  }, [isVisible]);

  return (
    // trap focus inside left pane when it is visible (for accessibility)
    <FocusTrap active={active}>
      <div
        data-cy-right-pane-visible={isVisible}
        className={clsx(styles.RightPane, {
          [styles.isVisible]: isVisible
        })}
      >
        <div className={styles.background} />
        <div className={styles.wrapper}>
          <div className={styles.header} data-cy="pane-close">
            <Button onClick={handleClose}>
              <Close stroke={styles.gray50} width={10} height={10} />
            </Button>
          </div>
          <div className={styles.content} data-cy="RightPane-content">
            <div className={styles.content}>
              {type && props && getTemplate(type, props)}
            </div>
          </div>
        </div>
      </div>
    </FocusTrap>
  );
}

function getTemplate(type: string, props: { [key: string]: any }) {
  const Content = DRAWER_DISPATCHER[type];
  return <Content {...props} />;
}
