/* Navbar Component */

// External
import React from "react";
import clsx from "clsx";
import Image from "next/image";

// styles
import styles from "./styles.module.scss";

export default function Footer() {
  return (
    <footer className={clsx(styles.Footer)}>
      <Image
        src="/svgs/logo_primary.svg"
        alt="Pario Health Logo"
        width={30}
        height={30}
      />
      <div>Pario Health Inc 2023 ©</div>
      <a href="mailto:info@pariohealth.com">Contact Us</a>
    </footer>
  );
}
