/* DetailTimeline Component */
/* External Imports */
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";

/* Local Imports */

// components
import Input from "@/components/input";
import Icon from "../icons";

// constants
import { EVENT_LABELS, STAGES } from "../flows/labor/constants";
import { STATUS_KEYS } from "@/globals/constants";

// store
import { RootState } from "@/store/store";
import {
  usePregnancyLaborRecurringEventUpdateMutation,
  usePregnancyLaborRecurringEventDeleteMutation,
  LaborSheetEvents,
  IdType,
  LaborEventInfo
} from "@/store/services/pregnancy";
import { LaborSheet, LaborSheetEventType, PatientInfo } from "@/store/services/patient";
import { addAlertToToastTrough } from "../toastTrough/toastSlice";

// utils
import {
  convertUtcIntToLocalDatetime,
} from "../scheduling/calendars/utils";
import { parseDateFromString } from "@/utils/api/time";
// styles
import styles from "./styles.module.scss";
import DetailForm from "./detailForm";
import { useEffect, useMemo, useState } from "react";

/* DetailTimeline Typescript Interface */
interface DetailTimelineProps {
  laborSheet?: LaborSheet;
  patient: PatientInfo;
  stage: keyof typeof STAGES;
  lastClickedEventId: string;
  disabled?: boolean;
  onFormSubmit: (formData: any, eventId: string, scope: IdType) => void;
  onDelete: (id: string, type: LaborSheetEventType, stage: string) => void;
}

export default function DetailTimeline({
  laborSheet,
  patient,
  stage,
  lastClickedEventId,
  disabled = false,
  onFormSubmit,
  onDelete
}: DetailTimelineProps) {
  /* Redux */
  const dispatch = useDispatch();
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const [upsertRecurringLaborEvent] =
    usePregnancyLaborRecurringEventUpdateMutation();

  /* Local State */
  const [currentEvent, setCurrentEvent] = useState<LaborEventInfo>();

  const events = useMemo(() => {
    if (!laborSheet || !laborSheet[stage] || laborSheet[stage].length === 0) {
      return [];
    }

    return laborSheet[stage];
  }, [laborSheet, stage])

  /* Effects */
  useEffect(() => {
    if (lastClickedEventId) {
      const newCurrentEvent = events.find(event => {
        return event.event_id == lastClickedEventId
      });
      setCurrentEvent(newCurrentEvent);
    }
  }, [events])

  /* Event Handlers */
  const handleRemoveEvent = (laborEventId: string, type: string) => {
    if (patient && patient.pregnancy) {
      const { pregnancy } = patient;

      let scope = "medication" as IdType;
      if (type === "RECURRING_EVENT") {
        scope = "recurring_event" as IdType;
      }

      const payload = {
        pregnancyId: pregnancy.pregnancy_id,
        stage,
        laborEventId,
        scope
      };

      onDelete(laborEventId, type as LaborSheetEventType, stage);
    }
  };

  const handleTimestampEntry = (
    time: string,
    laborEventId: string,
    type: string
  ) => {
    const timestring = parseDateFromString(time);
    const laborEventUpdate = {
      event_id: laborEventId,
      start: timestring,
      authors: [sessionInfo?.user_id as number]
    };

    let scope = "medication" as IdType;
    if (type === "RECURRING_EVENT") {
      scope = "recurring_event" as IdType;
    }

    // update this specific event
    if (patient && patient.pregnancy) {
      // should start timestamp be handled on backend?
      const { pregnancy } = patient;

      const payload = {
        pregnancyId: pregnancy.pregnancy_id,
        stage,
        laborEventId,
        scope,
        laborEventUpdate
      };

      upsertRecurringLaborEvent(payload)
        .unwrap()
        .then(res => {
          launchToast(`Event timestamp recorded`, STATUS_KEYS.SUCCESS);
        })
        .catch(err =>
          launchToast(`Oops! Labor could not be updated`, STATUS_KEYS.ERROR)
        );
    }
  };


  const launchToast = (message: string, type: string) => {
    dispatch(
      addAlertToToastTrough({
        message,
        type
      })
    );
  };

  return (
    <>
      <div className={clsx(styles.DetailTimeline)}>
        {events &&
          events.map(event => (
            <div key={event.event_id} className={styles.event}>
              <Input
                name={`${event.event_id}-event-timestamp`}
                id={`${event.event_id}-event-timestamp`}
                hiddenLabel
                // @ts-ignore
                label={`${event.forms?.name} at ${convertUtcIntToLocalDatetime(
                  event.start as number
                ).format("hh:mm:ss A")}`}
                type="text"
                value={convertUtcIntToLocalDatetime(
                  event.start as number
                ).format("hh:mm:ss A")}
                isSmall
                onSubmit={(time: string) =>
                  handleTimestampEntry(time, event.event_id, event.type)
                }
                disabled={true}
              />
              <button
                onClick={() => setCurrentEvent(event)}
                disabled={disabled}
              >
                <div
                  className={clsx(styles.marker, {
                    [styles.selected]: event === currentEvent
                  })}
                >
                  <div className={styles.innerMarker} />
                </div>
              </button>
              <p className="blue700 t4 tMd">
                {/* @ts-ignore */}
                {EVENT_LABELS[event.forms?.name] ||
                  EVENT_LABELS[event.event_id] ||
                  "Progress Update"}
              </p>
              <button
                onClick={() => handleRemoveEvent(event.event_id, event.type)}
                disabled={disabled}
              >
                <Icon svg="close_grey" height={8} width={8} />
              </button>
            </div>
          ))}
        <div className={styles.downArrow}>
          <Icon svg="chevron_down_grey" />
        </div>
      </div>
      {currentEvent && (
        <DetailForm
          event={currentEvent}
          onSubmit={onFormSubmit}
        />
      )}
    </>
  );
}
