/* Assessment and Plan Section */
/* External Imports */
import clsx from "clsx";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useForm,
  Controller,
  FieldValues,
  UseFormRegister
} from "react-hook-form";

/* Local Imports */

// components
import Tag from "@/components/tag";
import Button from "@/components/button";
import Icon from "@/components/icons";
import ComboboxSelect from "@/components/input/combobox";
import LabResults from "@/components/labOrdering/results";

// constants
import { MODAL_TYPES } from "@/components/modal/dispatcher";
import { STATUS_KEYS, STYLES } from "@/globals/constants";

// store
import {
  EncounterInfo,
  EncounterType,
  Icd10CodeDetails,
  LabOrderInfo
} from "@/store/services/encounter";
import {
  useLazyCodingSearchIcd10Query,
  useLazyCodingSearchCptQuery,
  useCodingIcd10LookupMutation,
  useCodingCptLookupMutation,
  CptCodeDetails
} from "@/store/services/coding";
import { useEncounterUpdateMutation } from "@/store/services/encounter";
import { PatientInfo } from "@/store/services/patient";
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { useGetFeatureFlagsQuery } from "@/store/services/system";

// styles
import styles from "./styles.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import MedicalCoding from "./medicalCoding";

/* AssessmentPlan Typescript Interface */
interface AssessmentPlanProps {
  encounter: EncounterInfo;
  patient?: PatientInfo;
  register?: UseFormRegister<FieldValues>;
  isEditable?: boolean;
  showLabOrdering?: boolean;
}

const HEADERS = ["ICD 10 codes", "CPT codes", ""];

/*
 * First, create a `pendingEncounter` which begins as a copy of the passed in encounter.
 * Then, when we add a new diagnosis, we add an entry to the `pendingEncounter.diagnoses` field.
 */

export default function AssessmentPlan({
  encounter,
  patient,
  isEditable = true,
  showLabOrdering = true
}: AssessmentPlanProps) {
  /* Local State */
  const { data: features } = useGetFeatureFlagsQuery();
  const [draftEncounter, setDraftEncounter] =
    useState<EncounterInfo>(encounter);
  const [lookupIcd10Codes, { data: icd10Details = {} }] =
    useCodingIcd10LookupMutation();
  const [lookupCptCodes, { data: cptDetails = {} }] =
    useCodingCptLookupMutation();

  useEffect(() => setDraftEncounter(encounter), [encounter]);
  useEffect(() => {
    if (encounter.orders?.length != draftEncounter.orders?.length) {
      setDraftEncounter({ ...draftEncounter, orders: encounter.orders });
    }

    const draftOrders: Record<string, LabOrderInfo> = (
      draftEncounter.orders || []
    ).reduce((combined, order) => {
      return {
        ...combined,
        [order.order_id]: order
      };
    }, {});

    for (const order of encounter.orders || []) {
      if (!draftOrders[order.order_id]) {
        setDraftEncounter({ ...draftEncounter, orders: encounter.orders });
        return;
      }
      if (draftOrders[order.order_id]?.updated != order.updated) {
        setDraftEncounter({ ...draftEncounter, orders: encounter.orders });
        return;
      }
    }
  }, [encounter]);

  // TODO(josh): On render, get list of ICD-10 codes associated with this encounter type
  // lookup ICD-10 code details whenever they change
  useEffect(() => {
    const diags = Object.keys(draftEncounter.diagnoses || {});
    if (diags.length > 0) {
      lookupIcd10Codes({
        body: diags.map(key => parseInt(key))
      });

      const cptCodeIDs: Set<number> = new Set();
      for (const diagInfo of Object.values(draftEncounter.diagnoses || {})) {
        for (const cptID of diagInfo.cpt) {
          cptCodeIDs.add(cptID);
        }
      }

      if (cptCodeIDs.size > 0) {
        lookupCptCodes({ body: Array.from(cptCodeIDs) });
      }
    }
  }, [draftEncounter]);

  function addCPT(icd10ID: string, cpt: CptCodeDetails) {
    setDraftEncounter(draftEncounter => {
      const existingCptCodes = draftEncounter.diagnoses
        ? draftEncounter.diagnoses[icd10ID].cpt
        : [];
      const newDraft = {
        ...draftEncounter,
        diagnoses: {
          ...draftEncounter.diagnoses,
          [icd10ID]: {
            ...(draftEncounter.diagnoses || {})[icd10ID],
            cpt: [...existingCptCodes, cpt.cpt_id]
          }
        }
      };

      handleUpdateEncounter(newDraft);
      return newDraft;
    });
  }

  function removeCPT(icd10ID: string, cptID: number) {
    setDraftEncounter(draftEncounter => {
      const existingCptCodes = draftEncounter.diagnoses
        ? draftEncounter.diagnoses[icd10ID].cpt
        : [];
      const newDraft = {
        ...draftEncounter,
        diagnoses: {
          ...draftEncounter.diagnoses,
          [icd10ID]: {
            ...(draftEncounter.diagnoses || {})[icd10ID],
            cpt: existingCptCodes.filter(id => id != cptID)
          }
        }
      };

      handleUpdateEncounter(newDraft);
      return newDraft;
    });
  }

  function addIcd10(icd10: Icd10CodeDetails) {
    setDraftEncounter(draftEncounter => {
      const newDraft = {
        ...draftEncounter,
        diagnoses: {
          ...draftEncounter.diagnoses,
          [icd10.icd_id]: {
            cpt: [],
            lab_orders: [],
            rx_orders: []
          }
        }
      };

      handleUpdateEncounter(newDraft);
      return newDraft;
    });
  }

  function removeIcd10(icd10Id: string) {
    setDraftEncounter(draftEncounter => {
      const newDraft = {
        ...draftEncounter,
        diagnoses: Object.fromEntries(
          Object.entries(draftEncounter.diagnoses || {}).filter(
            ([diagIcd10Id, _]) => diagIcd10Id != icd10Id
          )
        )
      };

      handleUpdateEncounter(newDraft);
      return newDraft;
    });
  }

  /* Redux */
  const dispatch = useDispatch();
  const [searchICD10, { data: icd10SearchResults }] =
    useLazyCodingSearchIcd10Query();
  const [searchCPT, { data: cptSearchResults }] = useLazyCodingSearchCptQuery();
  const [updateEncounter, updateEncounterRequest] =
    useEncounterUpdateMutation();
  useEffect(() => {
    if (updateEncounterRequest.isError) {
      console.error("failed to update encounter", updateEncounterRequest.error);
      dispatch(
        addAlertToToastTrough({
          message: "Failed to update encounter",
          type: STATUS_KEYS.ERROR
        })
      );
    }
  }, [updateEncounterRequest]);

  /* React Use Form Configuration */
  const {
    register,
    getValues,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({});

  useEffect(() => {
    console.log("FORM ERRORS", errors);
    console.log("FORM VALUES", getValues());
  }, [errors]);

  /* Event Handlers */
  const handleUpdateEncounter = async (draftEncounter: EncounterInfo) => {
    // TODO: determine which fields to grab from the form
    const payload = {
      encounterId: encounter.encounter_id,
      encounterCreateUpdatePayload: {
        patient_id: encounter.patient.user_id,
        provider_id: encounter.provider.user_id,
        encounter_type: draftEncounter.encounter_type as EncounterType,
        diagnoses: draftEncounter.diagnoses
      }
    };

    updateEncounter(payload);
  };

  /* Effects */

  /* Event Handlers */
  const openTestSelectionModal = () => {
    dispatch(setModalIsOpen(true));
    dispatch(
      setModalContent({
        type: MODAL_TYPES.TEST_SELECTION,
        props: { encounter }
      })
    );
  };

  /* Event Handlers */
  const handleOpenProblemListModal = () => {
    if (patient) {
      dispatch(setModalIsOpen(true));
      dispatch(
        setModalContent({
          type: MODAL_TYPES.PROBLEMS,
          props: {
            problems: patient.problems,
            patientId: patient.user_id,
            title: "Problems"
          }
        })
      );
    }
  };

  const handleOpenPrescriptionModal = () => {
    dispatch(setModalContent({ type: MODAL_TYPES.RX, props: { patient } }));
    dispatch(setModalIsOpen(true));
  };

  return (
    <form
      className={clsx(styles.AssessmentPlan)}
      onSubmit={handleSubmit(() => handleUpdateEncounter(draftEncounter))}
    >
      {/* LABWORK ----------------------------------------------------------------------------------------------------------------------------- */}
      {features?.lab_ordering_enabled && showLabOrdering && (
        <div>
          <div className={styles.header} data-cy="add-lab-button">
            Labwork
            <Button style={STYLES.SECONDARY} onClick={openTestSelectionModal}>
              <p>+</p>
            </Button>
          </div>
          <div className={styles.labWork} data-cy="encounter-labs">
            <LabResults encounter={draftEncounter} />
          </div>
        </div>
      )}

      {/* CODING --------------------------------------------------------------------------------------------------- */}
      {/* if this is an encounter that has been coded using the old data model, or if the new medical coding feature is not enabled, render the old coding grid */}
      {(!features?.medical_coding_v2_enabled || (encounter.diagnoses && Object.keys(encounter.diagnoses)?.length > 0 && !encounter.codings)) ? (
          <div className={styles.codingGrid} data-cy="medical-coding">
          {/* first row contains headers */}
          {HEADERS.map(header => (
            <div key={header} className={styles.header}>
              {header}
            </div>
          ))}

          {/* write a row for each diagnosis */}
          {Object.entries(draftEncounter.diagnoses || {}).map(([icd10Id]) => (
            <Fragment key={icd10Id}>
              {/* ICD-10 code */}
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0, minHeight: 0 }}
                  animate={{ opacity: 1, minHeight: 100 }}
                  exit={{ opacity: 0, minHeight: 0 }}
                  className={clsx(styles.cell, styles.first)}
                >
                  <div className={styles.diagnosis}>
                    {icd10Details[icd10Id]?.name ? (
                      <Tag
                        label={icd10Details[icd10Id]?.name}
                        type={STATUS_KEYS.INFO_GREY}
                      />
                    ) : (
                      <Skeleton height={40} width={50}></Skeleton>
                    )}

                    {icd10Details[icd10Id]?.description ? (
                      <p className={"light"}>
                        {icd10Details[icd10Id]?.description}
                      </p>
                    ) : (
                      <Skeleton height={50} width={100}></Skeleton>
                    )}
                    <button onClick={handleOpenProblemListModal}>
                      <Icon svg="info" />
                    </button>
                  </div>
                </motion.div>
              </AnimatePresence>

              {/* CPT codes */}
              <div className={styles.cell}>
                {isEditable && (
                  <Controller
                    name={`${icd10Id}_cpt_codes`}
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState
                    }) => (
                      <ComboboxSelect
                        label="Search for CPT code"
                        placeholder="Search"
                        hiddenLabel
                        options={cptSearchResults || []}
                        labelAcc={(item: CptCodeDetails) =>
                          `${item.cpt_code} - ${item.description}`
                        }
                        onChange={(e: CptCodeDetails) => {
                          if (e) {
                            onChange(e);
                            addCPT(icd10Id, e);
                          }
                        }}
                        onEnter={(term: string) => {
                          if (term) {
                            searchCPT({ term }).then(({ data }) => {
                              const cpt = data?.find(
                                entry => (entry.cpt_code = term)
                              );
                              if (cpt) addCPT(icd10Id, cpt);
                            });
                          }
                        }}
                        clearOnSelect
                        onSearch={term => {
                          searchCPT({ term });
                        }}
                        debounce
                      />
                    )}
                  />
                )}
                {/* display tags / pills for each CPT code */}
                {((draftEncounter.diagnoses || {})[icd10Id].cpt || []).map(
                  cptID => {
                    return (
                      <Tag
                        isStatus
                        key={cptID}
                        label={`${cptDetails[cptID]?.cpt_code} (${cptDetails[cptID]?.cpt_code})`}
                        type={STATUS_KEYS.INFO_GREY}
                        onRemove={
                          isEditable ? () => removeCPT(icd10Id, cptID) : undefined
                        }
                      />
                    );
                  }
                )}
              </div>

              {/* modifier codes */}
              {/* <div className={styles.cell}>
                <Controller
                  name={`${icd10Id}_modifier_codes`}
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <ComboboxSelect
                      label="Search for Modifier"
                      placeholder="Search"
                      hiddenLabel
                      onChange={onChange}
                      options={[]}
                    />
                  )}
                />
              </div> */}

              {/* rx orders */}
              <div className={styles.cell}>
                {isEditable && (
                  <Button
                    style={STYLES.ICON}
                    // remove diagnosis
                    onClick={() => removeIcd10(icd10Id)}
                    nativeButtonProps={{ disabled: !isEditable }}
                  >
                    <Icon svg="trash_grey" />
                  </Button>
                )}
              </div>
            </Fragment>
          ))}

          {/* Empty row for addition of codes */}
          {isEditable && (
            <>
              {/* select ICD 10 code first */}
              <div className={clsx(styles.cell, styles.first)}>
                <Controller
                  name={`search_icd10`}
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState
                  }) => (
                    <ComboboxSelect
                      label="Search for ICD10 code"
                      placeholder="Search"
                      hiddenLabel
                      options={icd10SearchResults || []}
                      labelAcc={item => `${item?.name} - ${item?.description}`}
                      onChange={e => {
                        if (e) {
                          onChange(e);
                          addIcd10(e);
                        }
                      }}
                      onEnter={term => {
                        searchICD10({ term }).then(({ data }) => {
                          const ICD = data?.find(entry => entry.name === term);
                          if (ICD) addIcd10(ICD);
                        });
                      }}
                      clearOnSelect
                      isDisabled={!isEditable}
                      onSearch={term => {
                        if (term.length >= 3) searchICD10({ term });
                      }}
                      debounce
                    />
                  )}
                />
              </div>
              {/* populate empty cells for this row (new row will populate above with input cells on ICD10 code selection)  */}
              {Array.from({ length: 5 }, (_, index) => (
                <div className={styles.cell} key={index} />
              ))}
            </>
          )}
        </div>
      ) : <MedicalCoding encounter={encounter} isReadOnly={!isEditable} />}

      {isEditable && features?.rx_enabled && (
        <div className={styles.actions}>
          <Button
            style={STYLES.SECONDARY}
            onClick={handleOpenPrescriptionModal}
            nativeButtonProps={{ disabled: !isEditable }}
          >
            <p>Order Rx</p>
          </Button>
        </div>
      )}
    </form>
  );
}
