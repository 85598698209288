/* AddAnnotation Name */
/* External Imports */
import clsx from "clsx";
import { useState } from "react";
import { useDispatch } from "react-redux";

/* Local Imports */

// components
import Icon from "@/components/icons";
import TextArea from "@/components/textArea";
import Button from "@/components/button";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";
// store
// store
import {
  useFileAnnotationCreateMutation,
  FileId,
  useFileDetailsQuery,
  useFileAnnotationUpdateMutation,
  AnnotationId
} from "@/store/services/file";
import { addAlertToToastTrough } from "../toastTrough/toastSlice";
// styles
import styles from "./styles.module.scss";

/* AddAnnotation Typescript Interface */
interface AddAnnotationProps {
  fileId: FileId;
  annotationId?: AnnotationId;
  content?: string;
  onCancel?: () => void;
  onSubmitted?: () => void;
  isEditing?: boolean;
}

export default function AddAnnotation({
  fileId,
  content,
  onSubmitted,
  onCancel,
  isEditing,
  annotationId
}: AddAnnotationProps) {
  /* Redux */
  const [textareaValue, setTextareaValue] = useState(content || "");

  /* Redux */
  const dispatch = useDispatch();
  const [createAnnotation, { isLoading: isCreating }] =
    useFileAnnotationCreateMutation();
  const [updateAnnotation, { isLoading: isUpdating }] =
    useFileAnnotationUpdateMutation();
  const { data: fileDetails } = useFileDetailsQuery({ fileId });
  /* Local State */

  /* Event Handlers */
  const handleAnnotationChange = (content: string) => {
    setTextareaValue(content);
  };

  // when annotation sent
  const handleSubmitAnnotation = () => {
    if (isEditing && annotationId) {
      updateAnnotation({
        annotationId,
        fileId,
        fileAnnotationUpdateRequest: {
          annotation: textareaValue,
          annotation_id: annotationId
        }
      })
        .unwrap()
        .then(() => handleSuccess())
        .catch(() => handleFailure());
    } else {
      createAnnotation({
        fileId,
        fileAnnotationCreateRequest: {
          annotation: textareaValue,
          file_id: fileId
        }
      })
        .unwrap()
        .then(() => handleSuccess())
        .catch(() => handleFailure());
    }
  };

  const handleSuccess = () => {
    // clear the record of the text field
    setTextareaValue("");
    onSubmitted && onSubmitted();
  };

  const handleFailure = () => {
    // handle error
    dispatch(
      addAlertToToastTrough({
        message: "Failed to save annotation. Please try again.",
        type: STATUS_KEYS.ERROR
      })
    );
  };
  return (
    <div className={clsx(styles.AddAnnotation, { [styles.edit]: isEditing })}>
      <TextArea
        label={`Annotations`}
        id="annotation_input"
        name="annotation_input"
        onChange={handleAnnotationChange}
        hiddenLabel
        useMenuBar={false}
        rows={4}
        content={textareaValue}
      />
      <div className="flex">
        <Button style={STYLES.SECONDARY} onClick={() => onCancel && onCancel()}>
          Cancel
        </Button>
        <Button
          style={STYLES.PRIMARY}
          // disable if there is no text in the text area
          nativeButtonProps={{
            disabled: !textareaValue || textareaValue === "<p></p>"
          }}
          loading={isCreating}
          onClick={handleSubmitAnnotation}
        >
          <Icon svg="send" />
        </Button>
      </div>
    </div>
  );
}
