/* ClaimOutcome Name */
/* External Imports */
import clsx from "clsx";
import { FormEvent, Fragment, useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

/* Local Imports */

// components
import Input from "../input";
import Tag from "../tag";
import Button from "../button";

// constants
import {
  METRICS,
  METRIC_LABELS,
  STATUS_KEYS,
  STYLES,
  TAG_COLOR_MAP
} from "@/globals/constants";

// store
import {
  ClaimId,
  useListClaimOutcomesQuery,
  useCreateClaimOutcomeMutation,
  useUpdateClaimOutcomeMutation,
  ClaimResult,
  ListClaimOutcomesApiResponse,
  ListClaimOutcomes200ResponseInner
} from "@/store/services/generated/claim";
import {
  CodingCptLookupApiResponse,
  CodingCptModifierLookupApiResponse,
  CodingIcd10LookupApiResponse,
  useCodingCptLookupMutation,
  useCodingCptModifierLookupMutation
} from "@/store/services/generated/coding";
import { usePatientGetTransactionsQuery } from "@/store/services/patient";
import { RootState } from "@/store/store";
import { useCodingIcd10LookupMutation } from "@/store/services/coding";
import { Coding, EncounterInfo } from "@/store/services/encounter";
import { addAlertToToastTrough } from "../toastTrough/toastSlice";

// styles
import styles from "./styles.module.scss";

const claimOutcomeInputMetrics = [
  METRICS.CHARGE_AMOUNT,
  METRICS.ALLOWED,
  METRICS.INSURANCE_PAID,
  METRICS.DISCOUNT,
  METRICS.APPLIED_DEDUCTIBLE,
  METRICS.COINSURANCE,
  METRICS.COPAY,
  METRICS.PATIENT_BALANCE
];

const DEFAULT_OUTCOME_METRICS = [
  METRICS.CPT_CODE,
  METRICS.ICD10_CODE,
  METRICS.MODS,
  METRICS.IS_BILLABLE,
  ...claimOutcomeInputMetrics,
  METRICS.CHARGE_RESULT,
  METRICS.DENIAL_CODE
];

const SUPERBILL_OUTCOME_METRICS = [
  METRICS.CPT_CODE,
  METRICS.ICD10_CODE,
  METRICS.MODS,
  METRICS.CHARGE_AMOUNT
];

/* ClaimOutcome Typescript Interface */
interface ClaimOutcomeProps {
  claimId?: ClaimId;
  encounter: EncounterInfo;
  isSuperbillView?: boolean;
}

export default function ClaimOutcome({
  claimId,
  encounter,
  isSuperbillView
}: ClaimOutcomeProps) {
  /* Redux */
  const dispatch = useDispatch();
  // session info
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  // get claim outcomes for this claim from the API
  const { data: claimOutcomes } = useListClaimOutcomesQuery(
    { claimId: claimId as string },
    { skip: !claimId }
  );

  const { data: transactions, } =
    usePatientGetTransactionsQuery(
      {
        patientId: encounter?.patient?.user_id as number,
        encounter: encounter.encounter_id
      },
      { skip: !encounter || !encounter?.patient?.user_id }
    );

  // mutations for creating and updating claim outcomes
  const [createClaimOutcome, { isLoading: isCreatingClaimOutcome }] =
    useCreateClaimOutcomeMutation();
  const [updateClaimOutcome, { isLoading: isUpdatingClaimOutcome }] =
    useUpdateClaimOutcomeMutation();

  // TEMP: fetch details to populate ICD10 and CPT info until encounter data model is updated
  const [getCptDetails] = useCodingCptLookupMutation();
  const [getICd10Details] = useCodingIcd10LookupMutation();
  const [getModifierDetails] = useCodingCptModifierLookupMutation();

  /* Local State */
  const [cptDetails, setCptDetails] =
    useState<CodingCptLookupApiResponse | null>(null);
  const [modifierDetails, setModifierDetails] = useState({});

  const [icd10CodeDetails, setIcd10CodeDetails] =
    useState<CodingIcd10LookupApiResponse | null>(null);

  // only allow biller to edit claim outcomes
  const [isEditMode, setIsEditMode] = useState(false);

  // using codings data model, collect unsubmitted claim outcomes
  const unsubmittedClaimData: ListClaimOutcomesApiResponse = useMemo(() => {
    let unsubmittedCodings: ListClaimOutcomesApiResponse = [];
    let encounterCodings: Coding[] = [];
    if (encounter.codings) {
      encounterCodings = encounter.codings.filter(
        coding =>
          !claimOutcomes
            ?.map(claimOutcome => claimOutcome.cpt_id)
            .includes(coding.cpt_id as number)
      );
    }
    // transform codings into shape of ListClaimOutcomes200ResponseInner
    if (encounterCodings.length > 0) {
      // @ts-ignore
      // TS mad because these aren't really the same shape, the codings don't include the dollar amounts, but that is okay
      unsubmittedCodings = encounterCodings.map(coding => ({
        claim_id: claimId,
        cpt_id: coding.cpt_id as number,
        cpt_code:
          cptDetails?.[coding.cpt_id as keyof typeof cptDetails]?.cpt_code ||
          "Unknown",
        coding
      }));
    }
    return unsubmittedCodings;
  }, [encounter.codings, icd10CodeDetails, claimOutcomes, cptDetails, claimId]);

  const claimOutcomeMetrics = useMemo(() => {
    return isSuperbillView
      ? SUPERBILL_OUTCOME_METRICS
      : DEFAULT_OUTCOME_METRICS;
  }, [isSuperbillView]);

  /* Effects */

  /* Event Handlers */
  useEffect(() => {
    // get cpt details for each unique cpt in the encounter
    // and store to state - doing this way because mutation is used to get cpt details
    if (encounter.codings) {
      const uniqueCpts = [
        ...new Set(
          Object.values(encounter.codings).flatMap(coding => coding.cpt_id)
        )
      ];
      if (uniqueCpts.length > 0) {
        getCptDetails({ body: uniqueCpts as number[] })
          .unwrap()
          .then(response => setCptDetails(response))
          .catch(error => console.error(error));
      }

      // get icd10 details for each unique icd10 in the encounter
      // and store to state - doing this way because mutation is used to get icd10 details
      const uniqueIcd10s = [
        ...new Set(encounter.codings.flatMap(coding => coding.icd10_ids))
      ];
      if (uniqueIcd10s.length > 0) {
        getICd10Details({ body: uniqueIcd10s as number[] })
          .unwrap()
          .then(response => setIcd10CodeDetails(response))
          .catch(error => console.error(error));
      }

      // get modifier details for each unique modifier in the encounter
      // and store to state - doing this way because mutation is used to get modifier details
      const uniqueModifiers = [
        ...new Set(encounter.codings.flatMap(coding => coding.modifier_ids))
      ];
      if (uniqueModifiers.length > 0) {
        getModifierDetails({ body: uniqueModifiers as number[] })
          .unwrap()
          .then(response => setModifierDetails(response))
          .catch(error => console.error(error));
      }
    }
  }, [encounter.codings]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    if (!claimId) return;
    e.preventDefault();
    // Parse parameters from form
    const form = e.currentTarget;
    const formData = new FormData(form);
    const data = Object.fromEntries(formData.entries());
    // get all cpts from claim outcomes
    const claimOutcomeCpts = claimOutcomes?.map(
      claimOutcome => claimOutcome.cpt_id
    );
    // get all new cpts from encounter
    const newOutcomeCpts = unsubmittedClaimData
      .map(encounter => encounter.cpt_id)
      .filter(cpt => !claimOutcomeCpts?.includes(cpt));
    // create new claim outcomes for the new cpts
    newOutcomeCpts.forEach(cpt => {
      const outcome = getOutcomeFromForm(claimId, cpt, data);
      createClaimOutcome({
        claimId,
        createClaimOutcomeRequest: outcome
      })
        .unwrap()
        .then(() => onSuccess("Claim Outcome Created"))
        .catch(() => onError("Claim Outcome Creation Failed"));
    });

    // update outcomes for the claim outcomes cpts
    claimOutcomeCpts?.forEach(cpt => {
      const updatedOutcome = getOutcomeFromForm(claimId, cpt, data);

      updateClaimOutcome({
        claimId,
        updateClaimOutcomeRequest: updatedOutcome
      })
        .unwrap()
        .then(() => onSuccess("Claim Outcome Updated"))
        .catch(() => onError("Claim Outcome Update Failed"));
    });
  };

  // API Success and Error Handlers
  // TODO: make these handlers global
  function onSuccess(message: string) {
    setIsEditMode(false);
    dispatch(addAlertToToastTrough({ message, type: STATUS_KEYS.SUCCESS }));
  }

  function onError(message: string) {
    dispatch(addAlertToToastTrough({ message, type: STATUS_KEYS.ERROR }));
  }

  const [checked, setChecked] = useState(
    [...(claimOutcomes || []), ...unsubmittedClaimData].reduce(
      (acc, claimOutcome) => ({
        ...acc,
        [claimOutcome.cpt_id]: claimOutcome.result === "APPROVED"
      }),
      {}
    )
  );

  return (
    <div className={clsx(styles.ClaimOutcome)}>
      <form onSubmit={handleSubmit} data-cy="claim-outcome-form">
        <div
          className={clsx(styles.outcomeForm, {
            [styles.isSuperbillView]: isSuperbillView
          })}
        >
          {claimOutcomeMetrics.map(metric => (
            <div key={metric} className={styles.claimOutcomeHeader}>
              {METRIC_LABELS[metric]}
            </div>
          ))}
          {/* iterate over the mock claim outcomes and render the data for each metric */}
          {/* if the data is not available for the metric, render an input component */}
          {claimOutcomes?.map(claimOutcome =>
            isEditMode ? (
              <WriteOutcomeRow
                claimOutcome={claimOutcome}
                key={claimOutcome.cpt_id}
                checked={checked}
                setChecked={setChecked}
                modifierDetails={modifierDetails}
                icd10CodeDetails={icd10CodeDetails}
              />
            ) : (
              <ReadOutcomeRow
                claimOutcome={claimOutcome}
                key={claimOutcome.cpt_id}
                modifierDetails={modifierDetails}
                icd10CodeDetails={icd10CodeDetails}
                isSuperbillView={isSuperbillView}
                fee={
                  transactions?.find(tsx => tsx.cpt_id === claimOutcome.cpt_id)
                    ?.amount
                }
              />
            )
          )}
          {unsubmittedClaimData.map(claimOutcome => {
            return isEditMode ? (
              <OutcomeRow
                claimOutcome={claimOutcome as ListClaimOutcomes200ResponseInner}
                key={claimOutcome.cpt_id}
                setChecked={setChecked}
                checked={checked}
                modifierDetails={modifierDetails}
                icd10CodeDetails={icd10CodeDetails}
              />
            ) : (
              <ReadOutcomeRow
                claimOutcome={claimOutcome as ListClaimOutcomes200ResponseInner}
                key={claimOutcome.cpt_id}
                modifierDetails={modifierDetails}
                icd10CodeDetails={icd10CodeDetails}
                isSuperbillView={isSuperbillView}
                fee={
                  transactions?.find(tsx => tsx.cpt_id === claimOutcome.cpt_id)
                    ?.amount
                }
              />
            );
          })}
        </div>
        {isSuperbillView && (
          <div>
            <div className={styles.total}>
              <p>
                Total Fees: $
                {transactions?.filter(x => x.type === "SERVICE_CHARGES")?.reduce((acc, tsx) => +acc + +tsx.amount, 0)}
              </p>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <p>
                Total Paid: $
                {transactions?.filter(x => x.type === "PATIENT_PAYMENT")?.reduce((acc, tsx) => +acc + +tsx.amount, 0)}
              </p>
            </div>
          </div>
        )}
        {sessionInfo?.is_biller && (
          <div className="grid2 padded">
            <Button
              type="button"
              style={STYLES.SECONDARY_FULL}
              nativeButtonProps={{ disabled: isEditMode }}
              onClick={() => setIsEditMode(true)}
            >
              Edit
            </Button>
            <div data-cy="submit-claim-outcome">
              <Button
                type="submit"
                style={STYLES.FULL_WIDTH}
                loading={isUpdatingClaimOutcome || isCreatingClaimOutcome}
                nativeButtonProps={{ disabled: !isEditMode }}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

function getOutcomeFromForm(claimId: string, cpt: number, data: any) {
  return {
    claim_id: claimId,
    cpt_id: cpt,
    is_billable: data[`is_billable-${cpt}`],
    charge_amount: data[`charge_amount-${cpt}`]
      ? data[`charge_amount-${cpt}`]?.toString()
      : "0",
    allowed: data[`allowed-${cpt}`]
      ? data[`allowed-${cpt}`].toString()
      : undefined,
    insurance_paid: data[`insurance_paid-${cpt}`]
      ? data[`insurance_paid-${cpt}`].toString()
      : undefined,
    applied_deductible: data[`applied_deductible-${cpt}`]
      ? data[`applied_deductible-${cpt}`].toString()
      : undefined,
    coinsurance: data[`coinsurance-${cpt}`]
      ? data[`coinsurance-${cpt}`].toString()
      : undefined,
    copay: data[`copay-${cpt}`] ? data[`copay-${cpt}`].toString() : undefined,
    patient_balance: data[`patient_balance-${cpt}`]
      ? data[`patient_balance-${cpt}`].toString()
      : undefined,
    result:
      data[`charge_result-${cpt}`] === "" // formdata processes the checked value as an empty string, the unchecked value is not present (undefined)
        ? ("APPROVED" as ClaimResult)
        : ("DENIED" as ClaimResult),
    denial_code: data[`denial_code-${cpt}`]
      ? data[`denial_code-${cpt}`]?.toString()
      : undefined
  };
}

interface OutcomeRowProps {
  claimOutcome: ListClaimOutcomes200ResponseInner;
  setChecked?: (checked: { [key: string]: boolean }) => void;
  checked?: { [key: string]: boolean };
  modifierDetails: CodingCptModifierLookupApiResponse;
  icd10CodeDetails: CodingIcd10LookupApiResponse | null;
  isSuperbillView?: boolean;
  fee?: string;
}

function ReadOutcomeRow({
  claimOutcome,
  modifierDetails,
  icd10CodeDetails,
  isSuperbillView,
  fee
}: OutcomeRowProps) {
  return (
    <Fragment key={claimOutcome.cpt_id}>
      <div
        className={clsx(styles.cell, styles.code)}
        title={claimOutcome.description}
      >
        <Tag label={claimOutcome.cpt_code} type={STATUS_KEYS.INFO_GREY} />
      </div>
      <div className={clsx(styles.cell, styles.code)}>
        {claimOutcome.coding?.icd10_ids?.map((icd10, index) => (
          <Tag
            key={index}
            label={icd10CodeDetails ? icd10CodeDetails[icd10]?.name : ""}
            type={STATUS_KEYS.INFO_GREY}
          />
        ))}
      </div>
      <div className={clsx(styles.cell, styles.code)}>
        {claimOutcome.coding?.modifier_ids?.map((modifier, _) => (
          <Tag
            key={modifier}
            label={modifierDetails[modifier]?.modifier_code}
            type={STATUS_KEYS.INFO_GREY}
          />
        ))}
      </div>
      {!isSuperbillView && (
        <div className={styles.cell}>
          <Tag
            label={claimOutcome.coding?.is_billable ? "Yes" : "No"}
            type={
              claimOutcome.coding?.is_billable
                ? STATUS_KEYS.SUCCESS
                : STATUS_KEYS.ERROR
            }
          />
        </div>
      )}
      {isSuperbillView && (
        <div className={styles.cell}>{fee ? "$" + fee : "-"}</div>
      )}
      {!isSuperbillView &&
        claimOutcomeInputMetrics.map(metric => (
          <div key={metric} className={styles.cell}>
            {metric === METRICS.DISCOUNT
              ? claimOutcome.allowed
                ? "$" +
                  (
                    parseInt(claimOutcome.charge_amount) -
                    parseInt(claimOutcome.allowed)
                  ).toFixed(2)
                : "-"
              : claimOutcome[metric?.toLowerCase() as keyof typeof claimOutcome]
              ? "$" +
                claimOutcome[metric?.toLowerCase() as keyof typeof claimOutcome]
              : "-"}
          </div>
        ))}
      {!isSuperbillView && (
        <div className={styles.cell}>
          {claimOutcome.result ? (
            <Tag
              label={METRIC_LABELS[claimOutcome.result] || claimOutcome.result}
              type={TAG_COLOR_MAP[claimOutcome.result] || STATUS_KEYS.INFO_GREY}
            />
          ) : (
            "-"
          )}
        </div>
      )}
      {!isSuperbillView && (
        <div className={styles.cell}>
          {claimOutcome.denial_code ? claimOutcome.denial_code : "-"}
        </div>
      )}
    </Fragment>
  );
}

function WriteOutcomeRow({
  claimOutcome,
  checked,
  setChecked,
  modifierDetails,
  icd10CodeDetails
}: OutcomeRowProps) {
  return (
    <Fragment key={claimOutcome.cpt_id}>
      <div
        className={clsx(styles.cell, styles.code)}
        title={claimOutcome.description}
      >
        <Tag label={claimOutcome.cpt_code} type={STATUS_KEYS.INFO_GREY} />
      </div>
      <div className={clsx(styles.cell, styles.code)}>
        {claimOutcome.coding?.icd10_ids?.map((icd10, index) => (
          <Tag
            key={index}
            label={icd10CodeDetails ? icd10CodeDetails[icd10].name : ""}
            type={STATUS_KEYS.INFO_GREY}
          />
        ))}
      </div>
      <div className={clsx(styles.cell, styles.code)}>
        {claimOutcome.coding?.modifier_ids?.map((modifier, _) => (
          <Tag
            key={modifier}
            label={modifierDetails[modifier]?.modifier_code}
            type={STATUS_KEYS.INFO_GREY}
          />
        ))}
      </div>
      <div className={styles.cell}>
        <Tag
          label={claimOutcome.coding?.is_billable ? "Yes" : "No"}
          type={
            claimOutcome.coding?.is_billable
              ? STATUS_KEYS.SUCCESS
              : STATUS_KEYS.ERROR
          }
        />
      </div>
      {claimOutcomeInputMetrics.map(metric => (
        <div key={metric} className={styles.cell}>
          {metric === METRICS.DISCOUNT ? (
            "-"
          ) : (
            <Input
              name={`${metric?.toLocaleLowerCase()}-${claimOutcome.cpt_id}`}
              value={
                claimOutcome[
                  metric?.toLocaleLowerCase() as keyof typeof claimOutcome
                ] as string
              }
              placeholder="0.00"
              hiddenLabel
              type="text"
              id={`${metric?.toLocaleLowerCase()}-${claimOutcome.cpt_id}`}
              label={METRIC_LABELS[metric]}
              isCondensed
            />
          )}
        </div>
      ))}
      <div className={styles.cell}>
        <div className={styles.result}>
          <p className="t5">Paid</p>
          <Input
            checked={checked?.[claimOutcome.cpt_id]}
            onChange={() =>
              // @ts-ignore
              setChecked(prevChecked => ({
                ...prevChecked,
                [claimOutcome.cpt_id]: !prevChecked[claimOutcome.cpt_id]
              }))
            }
            hiddenLabel
            type="checkbox"
            name={`charge_result-${claimOutcome.cpt_id}`}
            id={`charge_result-${claimOutcome.cpt_id}`}
            label="Paid"
          />
        </div>
      </div>
      <div className={styles.cell}>
        <Input
          name={`denial_code-${claimOutcome.cpt_id}`}
          value={claimOutcome.denial_code}
          placeholder="-"
          hiddenLabel
          type="text"
          id={`denial_code-${claimOutcome.cpt_id}`}
          label="Denial Code"
          isCondensed
        />
      </div>
    </Fragment>
  );
}

function OutcomeRow({
  claimOutcome,
  checked,
  setChecked,
  modifierDetails,
  icd10CodeDetails
}: OutcomeRowProps) {
  return (
    <Fragment key={claimOutcome.cpt_id}>
      <div
        className={clsx(styles.cell, styles.code)}
        title={claimOutcome.description}
      >
        <Tag label={claimOutcome.cpt_code} type={STATUS_KEYS.INFO_GREY} />
      </div>
      <div className={clsx(styles.cell, styles.code)}>
        {claimOutcome.coding?.icd10_ids?.map((icd10, index) => (
          <Tag
            key={index}
            label={icd10CodeDetails ? icd10CodeDetails[icd10].name : ""}
            type={STATUS_KEYS.INFO_GREY}
          />
        ))}
      </div>
      <div className={clsx(styles.cell, styles.code)}>
        {claimOutcome.coding?.modifier_ids?.map((modifier, _) => (
          <Tag
            key={modifier}
            label={modifierDetails[modifier]?.modifier_code}
            type={STATUS_KEYS.INFO_GREY}
          />
        ))}
      </div>
      <div className={styles.cell}>
        <Tag
          label={claimOutcome.coding?.is_billable ? "Yes" : "No"}
          type={
            claimOutcome.coding?.is_billable
              ? STATUS_KEYS.SUCCESS
              : STATUS_KEYS.ERROR
          }
        />
      </div>
      {claimOutcomeInputMetrics.map(metric => (
        <div key={metric} className={styles.cell}>
          {claimOutcome[metric as keyof typeof claimOutcome] ? (
            `$${claimOutcome[metric as keyof typeof claimOutcome]}`
          ) : metric === METRICS.DISCOUNT ? (
            "-"
          ) : (
            <Input
              name={`${metric?.toLocaleLowerCase()}-${claimOutcome.cpt_id}`}
              value={
                claimOutcome[
                  metric?.toLowerCase() as keyof typeof claimOutcome
                ] as string
              }
              placeholder="0.00"
              hiddenLabel
              type="text"
              id={`${metric?.toLocaleLowerCase()}-${claimOutcome.cpt_id}`}
              label={METRIC_LABELS[metric]}
              isCondensed
            />
          )}
        </div>
      ))}
      <div className={styles.cell}>
        {claimOutcome.result ? (
          <Tag
            label={METRIC_LABELS[claimOutcome.result] || claimOutcome.result}
            type={TAG_COLOR_MAP[claimOutcome.result] || STATUS_KEYS.INFO_GREY}
          />
        ) : (
          <div className={styles.result}>
            <p className="t5">Paid</p>
            <Input
              checked={checked?.[claimOutcome.cpt_id]}
              onChange={() =>
                // @ts-ignore
                // TODO: fix, had to timebox this
                setChecked(prevChecked => ({
                  ...prevChecked,
                  [claimOutcome.cpt_id]: !prevChecked[claimOutcome.cpt_id]
                }))
              }
              hiddenLabel
              type="checkbox"
              name={`charge_result-${claimOutcome.cpt_id}`}
              id={`charge_result-${claimOutcome.cpt_id}`}
              label="Paid"
            />
          </div>
        )}
      </div>
      <div className={styles.cell}>
        {claimOutcome.denial_code ? (
          claimOutcome.denial_code
        ) : claimOutcome.result === METRICS.PAYMENT_DENIED ? (
          <Input
            name={`denial_code-${claimOutcome.cpt_id}`}
            value={claimOutcome.denial_code}
            placeholder="Select..."
            hiddenLabel
            type="text"
            id={`denial_code-${claimOutcome.cpt_id}`}
            label="Denial Code"
            isCondensed
          />
        ) : (
          "-"
        )}
      </div>
    </Fragment>
  );
}
