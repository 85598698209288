/* Global Layout Component */
import { ReactElement } from "react";
import { useMediaQuery } from "react-responsive";


// Components
import Navbar from "./navbar";
import SideBar from "@/components/sidebar";
import Footer from "./footer";
import RightPane from "@/components/drawer/rightPane";
import LeftPane from "@/components/drawer/leftPane";
import Modal from "@/components/modal";
import ToastTrough from "@/components/toastTrough";
import MobileNav from "./mobileNav";
import ErrorBoundary from "../errorBoundary";

// Constants
import { PUBLIC_LAYOUTS } from "@/globals/constants";

// styles
import styles from "./styles.module.scss";
import dynamic from "next/dynamic";
import { useCustomUserLinks } from "@/globals/helpers/customHooks";
import Copilot from "../copilot";

// disable ssr for authenticated components
const Authenticated = dynamic(() => import("@/components/auth"), {
  ssr: false
});

export interface Layout {
  children: ReactElement | ReactElement[];
  type: string;
}

// in the case user ends up with a sidebar without links

export default function Layout({ children, type }: Layout) {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const links = useCustomUserLinks();

  return (
    <>
      {PUBLIC_LAYOUTS.includes(type) ? (
        // layout for public facing routes (e.g. login / landing)
        <>
          <Navbar type={type} />
          <ErrorBoundary>
            <main>{children}</main>
          </ErrorBoundary>
          <Footer />
        </>
      ) : (
        // layout for private routes
        <Authenticated>
          {isMobile ? (
            <div className={styles.MobileLayout}>
              <MobileNav links={links} />
              <main>{children}</main>
              <Modal />
            </div>
          ) : (
            <div className={styles.PrivateLayout}>
              <ToastTrough />

              <SideBar links={links} />
              <LeftPane></LeftPane>
              <div className={styles.contentWrapper}>
                <ErrorBoundary>
                  <main>{children}</main>
                  <Footer />
                </ErrorBoundary>
              </div>
              <RightPane />
              <Modal />
            </div>
          )}
        </Authenticated>
      )}
    </>
  );
}
