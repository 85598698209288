/* ViewBody Name */
/* External Imports */
import { ReactNode, useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";

/* Local Imports */

// components
import Annotation from "@/components/annotations";
import AddAnnotation from "@/components/annotations/addAnnotation";
import LayoutLeft from "../../../public/svgs/layout-left.svg";
import Button from "../button";

// constants
import { STYLES } from "@/globals/constants";
// store
import {
  FileId,
  useFileDetailsQuery,
  FileAnnotation
} from "@/store/services/file";
import { RootState } from "@/store/store";

// styles
import styles from "./styles.module.scss";

/* ViewBody Typescript Interface */
interface ViewBodyProps {
  children: ReactNode;
  fileId?: FileId;
}

export default function ViewBody({ children, fileId }: ViewBodyProps) {
  /* Redux */
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const { data: fileDetails } = useFileDetailsQuery(
    { fileId: fileId as FileId },
    { skip: !fileId }
  );
  const [addAnnotationOpen, setAddAnnotationOpen] = useState(false);

  /* Local State */
  const [showAnnotations, setShowAnnotations] = useState(false);

  /* Effects */

  /* Event Handlers */

  function renderAddButton() {
    return (
      <button
        className={styles.addAnnotation}
        onClick={() => setAddAnnotationOpen(true)}
      >
        +
      </button>
    );
  }

  const handleClose = () => {
    setAddAnnotationOpen(false);
  };

  return (
    <div className={clsx(styles.ViewBody)}>
      <div className={styles.menu}>
        {fileId && !sessionInfo?.is_patient && (
          <div className={styles.icon}>
            <Button
              style={STYLES.ICON}
              onClick={() => setShowAnnotations(!showAnnotations)}
            >
              <LayoutLeft
                stroke={showAnnotations ? styles.primary700 : styles.gray500}
                height={20}
                width={20}
              />
              <div className="light">File Annotations</div>
            </Button>
          </div>
        )}
      </div>
      {/* The PDF */}
      <div className={styles.content}>
        <div className={styles.contentViewer}>{children} </div>
        {/* The annotations menu */}
        {showAnnotations && (
          <div className={styles.annotationWrapper}>
            {/* either display already existing annotations, or empty state prompting user to add some */}

            <>
              <div className={styles.overflow}>
                {fileDetails?.annotations?.map((annotation: FileAnnotation) => (
                  <Annotation
                    key={annotation.annotation_id}
                    fileAnnotation={annotation}
                  />
                ))}
              </div>

              <div className={styles.emptyState}>
                <div className={styles.addWrapper}>
                  {renderAddButton()}

                  {addAnnotationOpen && fileId && (
                    <AddAnnotation
                      fileId={fileId}
                      onCancel={handleClose}
                      onSubmitted={handleClose}
                    />
                  )}
                </div>
                <div className="tMd Med dark">Add Comments</div>
                {/* // TODO: move into copy constants */}
                <p className="xLight">Add annotations to the document</p>
              </div>
            </>
          </div>
        )}
      </div>
    </div>
  );
}
