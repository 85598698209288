/* Certification Name */
/* External Imports */
import clsx from "clsx";
import { ChangeEvent } from "react";

/* Local Imports */

// components
import Input from "@/components/input";
import Icon from "@/components/icons";

// constants

// store

// styles
import styles from "./styles.module.scss";

/* Certification Typescript Interface */
interface CertificationProps {
  onCertify: (certified: boolean) => void;
  encounterId?: number;
  isChecked?: boolean;
  isReadOnly?: boolean;
}

export default function Certification({
  encounterId,
  onCertify,
  isChecked = false,
  isReadOnly = false
}: CertificationProps) {
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <div className={clsx(styles.Certification)}>
      <Icon svg="certify" width={30} height={30} />
      <p className="t4 xLight">
        I certify that the above services were medically necessary and have been
        rendered
      </p>
      <Input
        type="checkbox"
        name={encounterId ? `certify.encounter-${encounterId}` : "certify"}
        id={encounterId ? `certify-encounter-${encounterId}` : "certify"}
        label="certify"
        onChange={(e: ChangeEvent<HTMLInputElement>) => onCertify(e.target?.checked)}
        checked={isChecked}
        disabled={isReadOnly}
      />
    </div>
  );
}
