/* SubmittedStamp Name */
/* External Imports */
import clsx from "clsx";

/* Local Imports */

// components
import Icon from "@/components/icons";
// constants

// store

// styles
import styles from "./styles.module.scss";

export default function UnsubmittedStamp() {
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <div className={clsx(styles.SubmittedStamp, styles.UnsubmittedStamp)}>
      <div className={styles.icon}>
        <Icon svg="warn_circle_outline" height={16} width={16} />
      </div>
      <p>Not submitted</p>
    </div>
  );
}
